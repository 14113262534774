// 登录
export const loginUrl = '/sys/login';
export const logoutUrl = '/sys/logout';

// 客户
export const customerInsertUrl = '/customer/insert';
export const customerUpdateUrl = '/customer/update';
export const customerDeleteUrl = '/customer/delete';
export const customerPageListUrl = '/customer/pageList';

// 产品
export const productInsertUrl = '/product/insert';
export const productUpdateUrl = '/product/update';
export const productDeleteUrl = '/product/delete';
export const productPageListUrl = '/product/pageList';

// 博客
export const blogInsertUrl = '/blog/insert';
export const blogUpdateUrl = '/blog/update';
export const blogDeleteUrl = '/blog/delete';
export const blogPageListUrl = '/blog/pageList';

// 字典
export const dictInsertUrl = '/dict/insert';
export const dictUpdateUrl = '/dict/update';
export const dictDeleteUrl = '/dict/delete';
export const dictPageListUrl = '/dict/pageList';
export const dictListUrl = '/dict/list';

// 询盘
export const enquiryPageListUrl = '/enquiry/pageList';


// 菜单
export const treeMenuByUser =  '/menu/treeMenuByUser';

// 文件
const fileUrl = {
    folderAndFile: "file/folder/folderAndFile", // 查询文件夹和文件
    add: "file/folder/insert", // 文件夹新增
    edit: "file/folder/update", // 文件夹修改
    selectFolderTree: "file/folder/selectFolderTree", // 文件夹树
    selectOne: "file/selectOne", // 根据fileCode查询文件或文件夹
    delete: "file/delete", // 文件删除
    upload: "file/upload", // 文件上传
    pageList: "file/pageList" // 文件分页
}

export { fileUrl }  