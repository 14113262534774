<template>
  <div class="page-common-style">
    <a-spin size="large" :spinning="isLoading" tip="文件上传中……">
      <div class="handle-bar-style">
        <a-row :gutter="[8, 8]">
          <a-col :span="16" style="margin-top: 5px">
            <a-breadcrumb separator=">">
              当前位置：
              <a-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="index">
                <a @click="goback(item)">{{ item.fileName }}</a>
              </a-breadcrumb-item>
            </a-breadcrumb>
          </a-col>

          <a-col :span="8">
            <a-input-search placeholder="搜索文件" class="search-input-style" />
            <a-button icon="folder-add" class="btn-style" @click="addFolder">创建目录</a-button>
            <a-upload multiple :file-list="fileList" :customRequest="customRequest" @change="handleChange"
              style="float: right; margin-right: 10px">
              <a-button icon="cloud-upload" :disabled="btnDisabled">上传</a-button>
            </a-upload>
          </a-col>
        </a-row>
      </div>

      <a-spin :spinning="tableIsLoading">
        <a-table rowKey="id" bordered :columns="columns" :data-source="parentFolderList" :pagination="pagination"
          size="middle" @change="handleTableChange" :customRow="customRow">
          <span slot="action" slot-scope="text, record">
            <a @click="editFolder(record)">编辑</a>
            <a-divider type="vertical" />

            <a-dropdown>
              <a>更多&nbsp;<a-icon type="down" /></a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a-popconfirm title="确定删除？" ok-text="是" cancel-text="否" @confirm="handleDelete(record)"
                    @cancel="cancel">
                    <a>删除</a>
                  </a-popconfirm>
                </a-menu-item>

                <a-menu-item v-if="record.fileType == 'folder'">
                  <a-upload multiple :file-list="fileList" :customRequest="customRequest" @change="handleChange">
                    <span>上传</span>
                  </a-upload>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
      </a-spin>
    </a-spin>

    <!-- 新增文件夹弹窗 -->
    <a-modal :title="title" :visible="modalVisible" :confirm-loading="confirmLoading" @ok="handleOk"
      @cancel="handleCancel">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="文件夹" prop="fileName">
          <a-input v-model="form.fileName" allow-clear />
        </a-form-model-item>
      </a-form-model>

      <a-form-model-item label="父级目录" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select defaultExpandParent v-model="form.parentCode" :treeData="data" :replaceFields="replaceFields"
          style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择父级目录"
          allow-clear></a-tree-select>
      </a-form-model-item>
    </a-modal>
  </div>
</template>

<script>
import { fileUrl } from "@/utils/url.js";

const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    align: "center",
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "文件名",
    dataIndex: "fileName",
    key: "fileName",
    align: "center",
    scopedSlots: { customRender: "fileName" },
    ellipsis: true,
  },
  {
    title: "文件类型",
    dataIndex: "fileType",
    key: "fileType",
    align: "center",
    width: 100,
    customRender: function (text) {
      return text == "folder" ? "目录" : text;
    },
  },
  {
    title: "上传人",
    dataIndex: "createName",
    key: "createName",
    align: "center",
    width: 150,
    ellipsis: true,
  },
  {
    title: "上传时间",
    dataIndex: "createTime",
    key: "createTime",
    align: "center",
    width: 150,
    customRender: function (text) {
      return !text ? "" : text.length > 10 ? text.substr(0, 10) : text;
    },
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "",
    align: "center",
    scopedSlots: { customRender: "action" },
    width: 120,
  },
];

export default {
  data() {
    return {
      columns,
      headers: {}, // 请求头
      btnDisabled: false, // 上传按钮禁用
      selectedFolder: { fileCode: "", fileName: "" }, // 选中的目录
      isLoading: false, // 上传加载状态
      tableIsLoading: false, // 表格加载状态

      fileList: [], // 文件上传
      data: [], // 目录树
      parentFolderList: [], // 父文件夹
      breadcrumbList: [{ fileCode: "", fileName: "根目录" }], // 面包屑集合

      // 新增弹窗
      modalVisible: false,
      title: "",
      confirmLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {}, // 表单数据

      // 树key，title，children与数据集合关系对应
      replaceFields: {
        title: "fileName",
        key: "fileCode",
        children: "children",
        value: "fileCode",
      },

      // 分页参数
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30"],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      // 校验
      rules: {
        fileName: [
          {
            required: true,
            message: "请填写文件夹名！",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.folderTree(); // 查询目录树
    this.parentFolder();

    // 获取token，上传文件携带请求头
    // const token = this.$cookie.getCookie("token");
    // this.headers = { "X-Access-Token": token };
  },
  methods: {
    // 表格事件
    customRow(record, index) {
      return {
        on: {
          // 表格双击
          dblclick: (event) => {
            if (record.fileType == "folder") {
              this.tableIsLoading = true;
              this.selectedFolder.fileCode = record.fileCode;
              this.selectedFolder.fileName = record.fileName;

              // 面包屑中添加一个导航
              this.breadcrumbList.push({
                fileCode: record.fileCode,
                fileName: record.fileName,
              });

              this.parentFolder(record.fileCode);
            }
          },
          // 表格单击
          click: (event) => {
            if (record.fileType == "folder") {
              this.selectedFolder.fileCode = record.fileCode;
              this.selectedFolder.fileName = record.fileName;
            } else {
              // this.btnDisabled = true;
              // this.selectedFolder = { fileCode: "" };
            }
          },
          // 鼠标移入
          // mouseenter: (event) => {
          //   if (record.fileType == "folder") {
          //     this.selectedFolder.fileCode = record.fileCode;
          //     this.selectedFolder.fileName = record.fileName;
          //   }
          // },
          // 鼠标移出
          // mouseleave: (event) => {
          //   if (record.fileType == "folder") {
          //     this.selectedFolder.fileCode = "";
          //     this.selectedFolder.fileName = "";
          //   }
          // }
        },
      };
    },
    // 面包屑返回
    goback(record) {
      this.tableIsLoading = true;
      for (var i = 0; i < this.breadcrumbList.length; i++) {
        if (this.breadcrumbList[i].fileCode === record.fileCode) {
          this.breadcrumbList = this.breadcrumbList.slice(0, i + 1);
          this.selectedFolder.fileCode = record.fileCode;
          this.selectedFolder.fileName = record.fileName;
        }
      }
      this.parentFolder(record.fileCode);
    },
    // 父文件夹
    parentFolder(val) {
      this.tableIsLoading = true;

      let params = {
        parentCode: val,
      };

      this.$axios.get(fileUrl.folderAndFile, { params }).then((res) => {
        if (res.success) {
          this.parentFolderList = res.result.records;
          this.pagination.total = res.result.total;
        }
        this.isLoading = false;
        this.tableIsLoading = false;
      });
    },
    // 查询文件目录树
    folderTree() {
      let params = {};
      this.$axios.get(fileUrl.selectFolderTree, { params }).then((res) => {
        if (res.success) {
          this.data = res.result;
        }
        this.isLoading = false;
      });
    },

    // 分页切换
    handleTableChange(pagination, filters, sorter) {
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field;
        this.isorter.order = "ascend" == sorter.order ? "asc" : "desc";
      }
      this.pagination = pagination;
      this.parentFolder(this.selectedFolder.fileCode);
    },
    // 文件删除取消
    cancel(e) {
      console.log(e);
      this.$message.warning("取消删除！");
    },
    // 文件删除
    handleDelete(record) {
      let params = {
        id: record.id,
        pathHead: record.pathHead,
        filePath: record.filePath,
        fileType: record.fileType,
        fileCode: record.fileCode,
      }

      this.$axios.delete(fileUrl.delete, { params }).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.parentFolder(record.parentCode);
        }
        this.isLoading = false;
      });
    },
    // 自定义文件上传
    customRequest(data) {
      this.isLoading = true;

      const formData = new FormData();
      formData.append("file", data.file);
      const token = this.$cookie.getCookie("token");
      formData.append("X-Access-Token", token);
      formData.append("parentCode", this.selectedFolder.fileCode);

      let headers = { "Content-Type": "multipart/form-data" };

      // if (this.selectedFolder.fileCode != "") {
      //   // 面包屑中添加一个导航
      //   this.breadcrumbList.push({
      //     fileCode: this.selectedFolder.fileCode,
      //     fileName: this.selectedFolder.fileName,
      //   });
      // }

      this.$axios
        .post(fileUrl.upload, formData, { headers })
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            // this.btnDisabled = true;
            this.parentFolder(this.selectedFolder.fileCode);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    // 上传文件回调
    handleChange(info) {
      debugger;
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} 上传成功！`);
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} 文件上传失败！`);
      }
    },

    // 添加文件夹
    addFolder() {
      this.title = "新增目录";
      this.modalVisible = true;
    },
    // 修改目录或修改文件
    editFolder(record) {
      this.title = "修改目录";
      this.modalVisible = true;

      // let params = {
      //   fileCode: record.fileCode,
      // };
      // this.$axios.get(fileUrl.selectOne, { params }).then((res) => {
      //   if (res.success) {
      //     this.form = res.result;
      //   }
      //   this.isLoading = false;
      // });

      this.form = JSON.parse(JSON.stringify(record));
    },
    // 提交
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;

          let method = "";
          let url = "";

          if (!this.form.id) {
            method = "post";
            url = fileUrl.add;
          } else {
            method = "put";
            url = fileUrl.edit;
          }

          let options = {
            method: method,
            data: this.form,
            url: url,
          };

          this.$axios(options)
            .then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.isLoading = false;
                this.handleCancel();
                this.folderTree();
                this.parentFolder();
                this.breadcrumbList = [{ fileCode: "", fileName: "根目录" }];
              }
            })
            .finally(() => {
              this.isLoading = false;
              this.confirmLoading = false;
              this.handleCancel();
            });
        } else {
          this.$message.warning("请填写表单！");
          return false;
        }
      });
    },
    // 取消
    handleCancel() {
      this.modalVisible = false;
      this.$refs.ruleForm.resetFields();
      this.form = {};

      // this.btnDisabled = true;
    },

    // 打开编辑
    handleEdit(record) {
      this.$refs.menuAddDom.menuTreeList = this.data;
      this.$refs.menuAddDom.edit(record);
    },
  },
};
</script>

<style scoped>
/**文件操作栏 */
.file-tool-box {
  border-left: 1px solid rgb(232, 232, 232);
  border-right: 1px solid rgb(232, 232, 232);
  border-top: 1px solid rgb(232, 232, 232);
  min-height: 50px;
}

/**按钮 */
.btn-style {
  margin-right: 10px;
  float: right;
}

/**上传按钮 */
.upload-btn-style {
  float: left;
  margin-right: 10px;
  float: right;
}

/**文件搜索栏 */
.search-input-style {
  width: 150px;
  margin: 0px 0px 0px 0px;
  /* margin: 10px 10px 0 0; */
  float: right;
}

/**文件列表：文件目录、文件列表 */
.file-table-style {
  border: 1px solid rgb(232, 232, 232);
}

/**文件夹树 */
/* .folder-tree-style {
      height: 100%;
     border-right: 1px solid rgb(232, 232, 232);
      min-height: 300px;
   } */
/**自定义表格头 */
.table-heard {
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-bottom: 1px solid rgb(232, 232, 232);
  background: rgb(239, 249, 255);
}

/**自定义表格内容 */
.table-content {
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-bottom: 1px solid rgb(232, 232, 232);
}

/**文字太长隐藏 */
.hidden-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**表格最后一行为空，美化样式 */
.table-last-row {
  height: 30px;
}

/**自定义鼠标右键菜单 */
.contextmenu {
  border: 1px solid rgb(232, 232, 232);
  margin: 0;
  background: #fff;
  /* z-index: 3000; */
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}

.contextmenu li {
  margin: 0;
  padding: 7px 16px;
  cursor: pointer;
}

.contextmenu li:hover {
  background: #eee;
}
</style>