<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <!-- <nav>
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
      </nav> -->
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
  name: "App",
  data() {
    return {
      locale: zhCN
    };
  }
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */

  width: 100%;
  height: 100%;
  background: #f0f2f5;
}

/* nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */


/**每个页面整体div样式 */
.page-common-style {
  margin: 0px 10px 0px 10px;
}

/**每个组件顶部的按钮操作栏 */
.handle-bar-style {
  margin: 0px 10px 15px 0px;
}

/**在全局CSS里引用 */
/**滚动条宽度 */
::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

/**滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #a1a3a9;
  border-radius: 3px;
}

/**有时，一些区域可能不显示滚动条 */
div::-webkit-scrollbar {
  width: 0;
}
</style>
