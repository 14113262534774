<template>
  <a-drawer :title="title" width="720" :body-style="{ paddingBottom: '80px' }" placement="right" :closable="false"
    :maskClosable="false" @close="onClose" :visible="visible">
    <a-spin :spinning="isLoading">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <!-- 产品公共信息 -->
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="产品分类" prop="productCategory">
              <a-select v-model="form.productCategory" mode="multiple" placeholder="请选择产品分类"
                :default-value="selectProductCategory" @change="handleChangeProductCategory">
                <a-select-option v-for="(item, index) in dictList" :key="index" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="产品编号" prop="productNumber">
              <a-input v-model="form.productNumber" placeholder="请输入产品编号" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="是否现货" prop="isExistingGoods">
              <a-radio-group name="radioGroup" v-model="form.isExistingGoods">
                <a-radio :value="true">
                  Yes
                </a-radio>
                <a-radio :value="false">
                  No
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="产品图片" prop="name">
              <!-- <upload ref="uploadComponent" :imageList="form.productImage" /> -->
              <a-spin size="large" :spinning="isLoadingUpload" tip="文件上传中……">
                <a-upload list-type="picture-card" :file-list="fileList" multiple :customRequest="customRequest"
                  @preview="handlePreview" @change="handleChange" :before-upload="beforeUpload" :remove="handleRemove">
                  <div v-if="fileList.length < 9">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      上传
                    </div>
                  </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </a-spin>
            </a-form-model-item>
          </a-col>
        </a-row>




        <!-- 产品多种语言描述 -->
        <!-- <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="语言" prop="locale">
              <a-select v-model="form.locale" @change="handleChange" placeholder="请选择语言">
                <a-select-option value="jack" v-for="(item, index) in localeDictList" :key="index" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row> -->

        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="产品标题" prop="productTitle">
              <a-input v-model="form.productTitle" placeholder="请输入产品标题" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="毛质" prop="hair">
              <a-input v-model="form.hair" placeholder="请输入产品标题" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="口管" prop="ferrule">
              <a-input v-model="form.ferrule" placeholder="请输入口管材质" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="刷柄" prop="handle">
              <a-input v-model="form.handle" placeholder="请输入刷柄材质" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="包装" prop="packagingType">
              <a-input v-model="form.packagingType" placeholder="请输入包装方式" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 产品详情 -->
        <a-row :gutter="20">
          <a-col :span="24">
            <MyEditor ref="editorComponent" v-model='content' @editorContent='contentChange' :editData="content" />
          </a-col>
        </a-row>
      </a-form-model>
    </a-spin>
    <div :style="{
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    }">
      <a-button :style="{ marginRight: '8px' }" @click="onClose">取消</a-button>
      <a-button type="primary" @click="handleSubmit" :disabled="isLoading"
        :icon="isLoading ? 'loading' : ''">确定</a-button>
    </div>
  </a-drawer>
</template>

<script>
import { productInsertUrl, productUpdateUrl, dictListUrl } from "@/utils/url";
import { handleProductInsert, handleProductUpdate } from "@/utils/api";
// import upload from "@/components/Upload";
import MyEditor from "@/components/MyEditor";

export default {
  components: {
    // upload, 
    MyEditor
  },
  data() {
    return {
      visible: false,
      title: "",
      labelCol: { span: 3 },
      wrapperCol: { span: 21 },
      isLoading: false,
      dictList: [], // 字典列表
      localeDictList: [], // 国际化字典列表

      selectProductCategory: [], // 已选择的产品分类

      form: {
        isExistingGoods: false,
        productLocaleEntity: {
          locale: '',
          productTitle: '',
          hair: '',
          ferrule: '',
          handle: '',
          packagingType: '',
          productDetail: ''
        }
      }, // 表单数据
      content: '', // 编辑器数据

      i18nEdit: false, // 多语言新增或编辑
      productI18nEntity: {}, // 多语言实体
      elementDisabled: false, // 表单元素是否禁用


      previewVisible: false, // 图片预览框
      isLoadingUpload: false, // 上传文件loading
      previewImage: '',
      fileList: [], // 照片墙列表
      filePathList: [], // 产片图片文件路径列表
      fileCodeList: [], // 产片图片文件路径列表

      // localeArray: [
      //   { label: "英文", value: "en" },
      //   { label: "法语", value: "fr" },
      //   { label: "德语", value: "de" },
      //   { label: "西班牙语", value: "es" },
      //   { label: "阿拉伯语", value: "ar" },
      //   { label: "日语", value: "ja" },
      //   { label: "韩语", value: "ko" },
      //   { label: "越南语", value: "vi" },
      //   { label: "乌克兰语", value: "uk" },
      //   { label: "俄语", value: "ru" },
      // ],

      // 校验
      rules: {
        productTitle: [
          { required: true, message: "请填写产品标题！", trigger: "blur" },
        ],
        productCategory: [
          { required: true, message: "请选择产品分类！", trigger: "change" },
        ],
        productImage: [
          { required: true, message: "请上传产品图片！", trigger: "change" },
        ],
        productDetail: [
          { required: true, message: "请填写产品描述！", trigger: "blur" }
        ]
      },
    };
  },
  methods: {
    // 新增
    add() {
      this.title = "新增产品";

      console.log('执行前==content', this.content);
      this.content = '';
      console.log('执行后==content', this.content);

      this.fileList = []; // 清空上传的文件列表
      this.form = {};
      this.$nextTick(() => {
        this.$refs.editorComponent.updateContent(''); // 清空富文本编辑器的内容
        this.content = '';
        this.$refs.editorComponent.token = this.$cookie.getCookie('token');
      });
      

      this.visible = true;

      this.queryDictList(); // 查询词典列表
      this.queryLocaleDictList(); // 查询国际化
    },
    // // 新增产品多语言
    // addI18n(record) {
    //   this.title = "新增产品";
    //   this.visible = true;
      
    //   this.fileList = []; // 清空上传的文件列表
    //   this.form = {};
    //   this.$nextTick(() => {
    //     this.$refs.editorComponent.updateContent(''); // 清空富文本编辑器的内容
    //     this.content = '';
    //     this.$refs.editorComponent.token = this.$cookie.getCookie('token');
    //   });
      
    //   this.queryDictList(); // 查询词典列表
    //   this.queryLocaleDictList(); // 查询国际化

    //   // 判断产品存在就可以向产品多语言描述中新增
    //   if (record.id != undefined && record.id != '' && record.id != null) {
    //     this.form = record;
    //     this.fileList = JSON.parse(record.productImage); // 图片处理
    //     this.elementDisabled = true;

    //     this.i18nEdit = true;
    //     this.productI18nEntity = {
    //       productCode: record.productCode,
    //     }
    //   }
    // },
    // 编辑
    edit(record) {
      this.title = "编辑产品";
      this.visible = true;

      // debugger
      this.fileList = JSON.parse(record.productImage); // 图片处理
      this.content = record.productDetail; // 富文本处理

      this.form = record;
      this.form.productDetail = record.productDetail;

      this.$nextTick(() => {
        this.$refs.editorComponent.updateContent(record.productDetail);
      });
      console.log('编辑器****', this.content)

      // console.log('content：', this.content)
      // this.form = JSON.parse(JSON.stringify(record));

      this.queryDictList(); // 查询词典列表
      this.queryLocaleDictList(); // 查询国际化

      this.form.productCategory = record.productCategory.split(',');
    },
    // 关闭抽屉
    onClose() {
      // this.$refs.ruleForm.resetFields();
      this.content = '';
      this.fileList = []; // 清空上传的文件列表
      this.$nextTick(() => {
        this.$refs.editorComponent.updateContent(''); // 清空富文本编辑器的内容
        // this.content = '';
      });
      this.form = {};
      this.visible = false;
    },
    handleRemove(data) {
      const index = this.fileList.findIndex(file => file.uid === data.uid);
      if (index !== -1) {
        this.fileList.splice(index, 1);
      }
    },
    beforeUpload(file) {
      const { type, size } = file;
      const limitType = type === 'image/jpeg' || type === 'image/png';
      if (!limitType) {
        this.$message.error('请上传 JPG、PNG 格式图片!');
      }
      const limitSize = size / 1024 / 1024 < 2;
      if (!limitSize) {
        this.$message.error('图片不可大于 2MB!');
      }
      return limitType && limitSize;
    },
    // 自定义文件上传
    customRequest(data) {
      this.isLoadingUpload = true;

      const formData = new FormData();
      formData.append("file", data.file);
      const token = this.$cookie.getCookie("token");

      formData.append("X-Access-Token", token);

      let headers = { "Content-Type": "multipart/form-data" };

      this.$axios.post("file/upload", formData, { headers }).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          let fileUrl = `${process.env.VUE_APP_API_BASE_URL}/file${res.result.filePath}/${res.result.fileName}.${res.result.fileSuffix}`;

          console.log("查看res::", res);

          this.fileList.push({
            uid: res.result.id,
            name: res.result.fileName,
            // status: 'done',
            url: fileUrl
          })

          this.filePathList.push(`${res.result.filePath}/${res.result.fileName}.${res.result.fileSuffix}`);
          this.fileCodeList.push(res.result.fileCode);
        }
      }).finally(() => {
        this.isLoadingUpload = false;
      });
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      console.log("查看handlePreview::", file.url)
      console.log("file.preview::", file.preview)

      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
        console.log("file.originFileObj::", file.originFileObj)
      }
      this.previewImage = file.url || file.preview;
      this.previewImage = file.url;
      console.log("this.previewImage::", this.previewImage)
      this.previewVisible = true;
    },
    // 上传中、完成、失败都会调用这个函数。文件状态改变的回调，返回为：
    handleChange({ fileList }) {
      // this.fileList = fileList;

      // console.log("file ===>", file)
      console.log("fileList ===>", fileList)
      // console.log("event ===>", event)
    },
    // 查询产品字典列表
    queryDictList() {
      let params = {
        category: "product"
      };

      this.$axios.get(dictListUrl, { params }).then(res => {
        if (res.success) {
          this.dictList = res.result;
        }
      });
    },
    // 查询国家字典列表
    queryLocaleDictList() {
      let params = {
        category: "locale"
      };

      this.$axios.get(dictListUrl, { params }).then(res => {
        if (res.success) {
          this.localeDictList = res.result;
        }
      });
    },
    contentChange(data) {
      console.log("编辑器数据::", data)
      this.content = data
    },
    // 产品分类多选
    handleChangeProductCategory(value) {
      // console.log('产品分类',value)
      // this.form.productCategory = value.join(',');

      this.form.productCategory = value;
      // console.log('产品分类::',this.form.productCategoryList)
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.isLoading = true;

          this.form.productDetail = this.content;
          // console.log('查看文件上传：', this.fileList);
          this.form.productImage = JSON.stringify(this.fileList);
          // 产品分类用数组转字符串
          this.form.productCategory = this.form.productCategory.join(",");

          this.productI18nEntity.productDetail = this.content;

          // 产品属性以json字符串存储一份
          // let productAttributesObject = {
          //   productNumber: this.form.productNumber,
          //   productTitle: this.form.productTitle,
          //   hair: this.form.hair,
          //   ferrule: this.form.ferrule,
          //   handle: this.form.handle,
          //   packagingType: this.form.packagingType,
          // };
          // var productAttributesJsonString = JSON.stringify(productAttributesObject)
          // console.log('============', productAttributesJsonString);
          // this.form.productAttributes = productAttributesJsonString;
          
          this.form.locale = 'en'; // 产品主表默认英文

          
          // this.form.productLocaleEntity = {
          //   locale: this.form.locale,
          //   productTitle: this.form.productTitle,
          //   hair: this.form.hair,
          //   ferrule: this.form.ferrule,
          //   handle: this.form.handle,
          //   packagingType: this.form.packagingType,
          //   productDetail: this.content,
          //   productAttributes: productAttributesJsonString
          // };



          // 产品图路径
          this.form.productImagePath = this.filePathList.join(",");
          this.form.fileCode = this.fileCodeList.join(",");


          if (!this.form.id) {
            this.addSubmit(this.form);
          } else {
            this.editSubmit(this.form);
          }


          // let method = "";
          // let url = "";

          // if (!this.form.id) {
          //   method = "post";
          //   url = productInsertUrl;
          // } else {
          //   method = "put";
          //   url = productUpdateUrl;
          // }

          // let options = {
          //   method: method,
          //   data: this.form,
          //   url: url
          // };


          // this.$axios(options).then(res => {
          //   if (res.success) {
          //     this.$message.success(res.message);
          //     this.onClose();
          //     this.$emit("refresh");
          //   }
          // })
          //   .finally(() => {
          //     this.isLoading = false;
          //     this.onClose();
          //   });
        } else {
          this.$message.warning("请填写表单！");
          return false;
        }
      });
    },
    addSubmit(params) {
      handleProductInsert(params).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.onClose();
          this.$emit("refresh");
        }
      }).finally(() => {
        this.isLoading = false;
        this.onClose();
      });
    },
    editSubmit(params) {
      handleProductUpdate(params).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.onClose();
          this.$emit("refresh");
        }
      }).finally(() => {
        this.isLoading = false;
        this.onClose();
      });
    }
  },
};
</script>

<style scoped></style>