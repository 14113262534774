<template>
  <iframe
    src="http://localhost:8082/fps/doc.html"
    width="100%"
    height="900px"
    frameborder="0"
    scrolling="auto"
  ></iframe>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style scoped>
</style>