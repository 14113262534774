import Vue from "vue";
import axios from "axios";
import { Modal, notification, message } from "ant-design-vue";

// 创建axios实例
const request = axios.create({
  baseURL: "/api", // 配置全局公共的请求路径
  timeout: 6000, // 请求超时时间
  withCredentials: true, // 是否允许携带 Cookie
});

// request.defaults.baseURL = "/api/"; // 配置全局公共的请求路径
// 设置全局的 Content-Type
request.defaults.headers.post["Content-Type"] = "application/json";


/**
 * 添加请求拦截器
 */
request.interceptors.request.use(config => {
  // const token = localStorage.getItem("token");
  const token = Vue.prototype.$cookie.getCookie("token");

  if (token) {
    config.headers["X-Access-Token"] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  console.log(config);
  return config;
}, error => {
  // notification.error({
  //   message: "请求错误！",
  //   description:
  //     "请联系管理员！",
  // });
  return Promise.reject(error);
});

/**
 * 添加响应拦截器
 */
request.interceptors.response.use(response => {
  if (!response.data.success) {
    let err = new Error(response.data.message);
    err.data = response.data;
    message.warning(response.data.message);
    return err.data;
  }

  /**
   * 前端token过期后，后端生成新的token，并将新token写入响应头
   * 前端获取后并存入cookie中
   * 这样每次请求的时候都会携带最新的token
   */
  let token = response.headers.token;
  if (token) {
    Vue.prototype.$cookie.setCookie("token", token, 0);
  }
  return response.data;
}, error => {
  console.log("错误信息：", error);
  console.log("错误信息：", error.response);
  switch (error.response.status) {
    case 500:
      Modal.error({
        content: "很抱歉，登录已过期，请重新登录！",
        okText: "重新登录",
        onOk() {
          Vue.prototype.$cookie.deleteCookie("token");
          window.location.reload();
        },
      });
      break;
  }
  return Promise.reject(error);
});


// 导出 Axios 实例
export default request;