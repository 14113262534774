<template>
  <div class="page-common-style">
    <div class="handle-bar-style">
      <a-row :gutter="16">
        <a-col class="gutter-row" :span="24">
          <a-button type="primary" icon="plus" @click="handleAdd">新增</a-button>
        </a-col>
      </a-row>
    </div>

    <a-spin :spinning="isLoading">
      <a-table :bordered="false" :columns="columns" rowKey="id" 
      :data-source="dataSource" size="middle" :pagination="pagination"
      :expanded-row-keys.sync="expandedRowKeys" :scroll="{ x: 900 }"
        @change="handleTableChange">
        <span slot="dict" slot-scope="text, record">
          <span v-for="dict in dictList" :key="dict.id" v-if="record.blogCategory === dict.value">
            {{ dict.label }}
          </span>
        </span>

        <span slot="image" slot-scope="text, record">
          <a-avatar shape="square" :size="64" :src="`${JSON.parse(record.blogImage)[0].url}`"
            @click="showImage(record)" />
        </span>

        <span slot="action" slot-scope="text, record">
          <a @click="handleAddLocale(record)">新增</a>
          <a-divider type="vertical" />
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定删除？" ok-text="是" cancel-text="否" @confirm="handleDelete(record.id)" @cancel="cancel">
            <a>删除</a>
          </a-popconfirm>
        </span>
        
        <template slot-scope="record" slot="expandedRowRender">
          <a-table :bordered="false" :columns="innerColumns" :data-source="record.childrens" :pagination="false">
            <span slot="operation" slot-scope="text, recordLocale" class="table-operation">
              <a @click="handleAddLocale(recordLocale)">新增</a>
              <a-divider type="vertical" />
              <a @click="handleEditLocale(recordLocale)">修改</a>
            </span>
          </a-table>
        </template>
      </a-table>
    </a-spin>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelModal" style="padding: 10px;">
      <img alt="example" style="width: 100%;" :src="previewImage" />
    </a-modal>

    <blog-form ref="handleFrom" @refresh="loadData" />
    <blog-locale-form ref="handleLocaleFrom" @refresh="loadData" />
  </div>
</template>

<script>
import { blogPageListUrl, blogDeleteUrl, dictListUrl } from "@/utils/url.js";
import BlogForm from "@/views/biz/blog/BlogForm.vue";
import BlogLocaleForm from "@/views/biz/blog/BlogLocaleForm.vue";

const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    align: "center",
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "文章标题",
    dataIndex: "blogTitle",
    key: "blogTitle",
    width: 150,
    align: "center",
  },
  {
    title: "文章分类",
    dataIndex: "blogCategory",
    key: "blogCategory",
    width: 100,
    align: "center",
    scopedSlots: { customRender: "dict" }
  },
  // {
  //   title: "产品图片",
  //   dataIndex: "blogImage",
  //   key: "blogImage",
  //   align: "center",
  //   scopedSlots: { customRender: "image" }
  // },
  {
    title: "图片显示",
    dataIndex: "blogImage",
    key: "blogImage",
    align: "center",
    // customRender: function (t, r, index) {
    //   let image = JSON.parse(t.blogImage);
    //   // console.log("查看image::", image[0].url);
    //   console.log("查看image::", JSON.parse(t.blogImage)[0].url);
    //   return image[0].url;
    // },
    scopedSlots: { customRender: "image" }
  },
  // {
  //   title: "文章内容",
  //   dataIndex: "blogContent",
  //   key: "blogContent",
  //   align: "center",
  // },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "",
    align: "center",
    scopedSlots: { customRender: "action" },
    width: 120,
  },
];

const innerColumns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    align: "center",
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "语言",
    dataIndex: "locale",
    key: "locale",
    width: 150,
    align: "center",
  },
  {
    title: "文章标题",
    dataIndex: "blogTitle",
    key: "blogTitle",
    width: 150,
    align: "center",
  },
  {
    title: "文章内容",
    dataIndex: "blogContent",
    key: "blogContent",
    align: "center",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "",
    align: "center",
    scopedSlots: { customRender: "operation" },
    width: 120,
  },
];

export default {
  components: {
    BlogForm,
    BlogLocaleForm
  },
  data() {
    return {
      columns,
      innerColumns,
      isLoading: false, // 是否为加载状态
      dataSource: [], // 表格数据
      dictList: [], // 字典列表
      previewVisible: false, // 图片弹框
      previewImage: '', // 点击表格图片弹窗显示
      expandedRowKeys: [], // 表格展开的列表

      // 分页参数
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30"],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
    };
  },
  created() {
    this.loadData();
    this.queryDictList();
  },
  methods: {
    // 查询字典列表
    queryDictList() {
      let params = {
        category: "blog"
      };

      this.$axios.get(dictListUrl, { params }).then(res => {
        if (res.success) {
          this.dictList = res.result;
        }
      });
    },
    // 查询列表
    loadData() {
      this.isLoading = true;

      let params = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };

      this.$axios.get(blogPageListUrl, { params }).then((res) => {
        if (res.success) {
          this.dataSource = res.result.records;
          this.pagination.total = res.result.total;
          console.log("查询结果::", this.dataSource);
        }
        this.isLoading = false;
      });
    },
    // 分页切换
    handleTableChange(pagination, filters, sorter) {
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field;
        this.isorter.order = "ascend" == sorter.order ? "asc" : "desc";
      }
      this.pagination = pagination;
      this.loadData();
    },
    handleCancelModal() {
      this.previewVisible = false;
    },
    showImage(record) {
      let imageUrl = JSON.parse(record.blogImage)[0].url;
      this.previewImage = imageUrl;
      this.previewVisible = true;
    },
    // 产品多语种新增
    handleAddLocale(record) {
      this.$refs.handleLocaleFrom.add(record);
    },
    // 产品多语言修改
    handleEditLocale(record) {
      this.$refs.handleLocaleFrom.edit(record);
    },
    // 打开新增
    handleAdd() {
      this.$refs.handleFrom.add();
    },
    // 打开编辑
    handleEdit(record) {
      // debugger
      this.$refs.handleFrom.edit(record);
    },
    // 删除取消
    cancel(e) {
      console.log(e);
      this.$message.warning("取消删除！");
    },
    // 删除
    handleDelete(val) {
      this.isLoading = true;

      let params = {
        id: val,
      };

      this.$axios.delete(blogDeleteUrl, { params }).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.loadData();
        }
        this.isLoading = false;
      });
    }
  },
};
</script>

<style scoped></style>