<template>
  <div class="page-common-style">
    <div class="handle-bar-style">
      <a-row :gutter="16">
        <a-col class="gutter-row" :span="24">
          <a-button type="primary" icon="plus" @click="handleAdd">新增</a-button>
        </a-col>
      </a-row>
    </div>

    <a-spin :spinning="isLoading">
      <a-table
        bordered
        :columns="columns"
        rowKey="id"
        :data-source="data"
        size="middle"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <span slot="status" slot-scope="status">
          <a-tag :color="status == false ? 'red' : 'green'">{{ status == false ? '已停用' : '已启用' }}</a-tag>
        </span>

        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定删除？"
            ok-text="是"
            cancel-text="否"
            @confirm="handleDelete(record.id)"
            @cancel="cancel"
          >
            <a>删除</a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-spin>

    <UserAdd ref="userFormDom" @refresh="loadData" />
  </div>
</template>

<script>
import UserAdd from "@/views/sys/user/UserAdd";

const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    align: "center",
    customRender: function(t, r, index) {
      return parseInt(index) + 1;
    }
  },
  {
    title: "姓名",
    dataIndex: "name",
    key: "name",
    align: "center"
  },
  {
    title: "性别",
    dataIndex: "sex",
    key: "sex",
    align: "center",
    customRender: function(text) {
      if (text == 0) {
        return "女";
      } else if (text == 1) {
        return "男";
      }
    }
  },
  {
    title: "手机号",
    dataIndex: "phone",
    key: "phone",
    align: "center"
  },
  {
    title: "所属公司",
    dataIndex: "cpyName",
    key: "cpyName",
    align: "center"
  },
  {
    title: "账号状态",
    dataIndex: "status",
    key: "status",
    align: "center",
    scopedSlots: { customRender: "status" }
    // customRender: function(text) {
    //   if (text == true) {
    //     return "启用";
    //   } else if (text == false) {
    //     return "停用";
    //   }
    // }
  },
  {
    title: "操作",
    dataIndex: "",
    align: "center",
    scopedSlots: { customRender: "action" },
    width: 120
  }
];

export default {
  components: {
    UserAdd
  },
  data() {
    return {
      columns,
      isLoading: false, // 是否为加载状态
      data: [], // 用户集合

      // pageSize: 20,
      // current: 1,
      // 分页参数
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30"],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },

      url: {
        pageList: "/user/pageList",
        delete: "/user/delete"
      }
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // 查询列表
    loadData() {
      this.isLoading = true;

      let params = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      };

      this.$axios.get(this.url.pageList, { params }).then(res => {
        if (res.success) {
          this.data = res.result.records;
          this.pagination.total = res.result.total;
        }
        this.isLoading = false;
      });
    },
    // 分页切换
    handleTableChange(pagination, filters, sorter) {
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field;
        this.isorter.order = "ascend" == sorter.order ? "asc" : "desc";
      }
      this.pagination = pagination;
      this.loadData();
    },
    // 打开新增
    handleAdd() {
      this.$refs.userFormDom.add();
    },
    // 打开编辑
    handleEdit(record) {
      this.$refs.userFormDom.edit(record);
    },
    // 删除取消
    cancel(e) {
      console.log(e);
      this.$message.warning("取消删除！");
    },
    // 删除
    handleDelete(val) {
      this.isLoading = true;
      this.$axios.delete(this.url.delete, { params: { id: val } }).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.loadData();
        }
        this.isLoading = false;
      });
    }
  }
};
</script>

<style scoped>
</style>
