import { render, staticRenderFns } from "./RoleMenu.vue?vue&type=template&id=75287e4d&scoped=true"
import script from "./RoleMenu.vue?vue&type=script&lang=js"
export * from "./RoleMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75287e4d",
  null
  
)

export default component.exports