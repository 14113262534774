<template>
  <div class="page-common-style">
    <a-spin size="large" :spinning="isLoading" tip="文件上传中……">
      <a-layout id="components-layout-demo-top-side-2" style="border: 1px solid rgb(232, 232, 232);">
        <a-layout-header class="header" style="background: #fff;  border-bottom: 1px solid rgb(232, 232, 232);">
          <a-row style="margin-left: -50px;">
            <a-col :span="24">
              <a-button icon="folder-add" class="btn-style" @click="addFolder">创建目录</a-button>
              <a-button icon="edit" :disabled="btnDisabled" class="btn-style" @click="editFolder">修改目录</a-button>
              <a-upload class="upload-btn-style" multiple :file-list="fileList" :customRequest="customRequest"
                @change="handleChange">
                <a-button type="primary" ghost icon="cloud-upload" :disabled="btnDisabled">上传</a-button>
              </a-upload>
              <a-input-search placeholder="搜索文件" class="search-input-style" />
            </a-col>
          </a-row>
        </a-layout-header>

        <a-layout>
          <a-layout-sider width="200" style="background: #fff; border-right: 1px solid rgb(232, 232, 232);">
            <a-directory-tree default-expand-all :treeData="data" :replaceFields="replaceFields" @select="onSelect"
              @expand="onExpand" @rightClick="openMenu">
            </a-directory-tree>
          </a-layout-sider>
          <a-layout>
            <a-layout-content :style="{
              background: '#fff',
              margin: 0,
              minHeight: '280px'
            }">
              <a-table :columns="columns" :data-source="fileArray" :pagination="pagination" size="middle"
                @change="handleTableChange">

                <span slot="image" slot-scope="text, record">
                  <a-avatar shape="square" :size="64" :src="`${useFileUrl}${record.filePath}/${record.fileName}.${record.fileSuffix}`"
                    @click="showImage(record)" />
                </span>

                <span slot="url" slot-scope="text, record">
                  <a-tooltip placement="topLeft">
                    <template slot="title">
                      <span>{{ useFileUrl }}{{ record.filePath }}/{{ record.fileName }}.{{ record.fileSuffix }}</span>
                    </template>
                    <a>{{ `${useFileUrl}${record.filePath}/${record.fileName}.${record.fileSuffix}` }}</a>
                  </a-tooltip>

                </span>
                <span slot="action" slot-scope="text, record">
                  <a @click="getFileUrl(record)">查看</a>
                  <a-divider type="vertical" />
                  <a @click="handleEdit(record)">编辑</a>
                  <a-divider type="vertical" />
                  <a-popconfirm title="确定删除？" ok-text="是" cancel-text="否" @confirm="handleDelete(record)"
                    @cancel="cancel">
                    <a>删除</a>
                  </a-popconfirm>
                </span>
              </a-table>
            </a-layout-content>
          </a-layout>
        </a-layout>
      </a-layout>
      <div :style="{
        right: 0,
        bottom: 0,
        width: '100%',
        padding: '10px 16px',
        background: '#fff',
        zIndex: 1
      }"></div>
    </a-spin>

    <!-- 创建目录，修改目录 -->
    <a-modal :title="title" :visible="modalVisible" :confirm-loading="confirmLoading" @ok="handleOk"
      @cancel="handleCancel">
      <a-form-model v-if="folderFlag == 0" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-form-model-item label="文件夹" prop="fileName">
          <a-input v-model="form.fileName" allow-clear />
        </a-form-model-item>
      </a-form-model>

      <a-form-model v-if="folderFlag == 1" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-form-model-item label="文件名" prop="fileName">
          {{ form.fileName }}
        </a-form-model-item>
      </a-form-model>

      <a-form-model-item label="父级目录" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select v-model="form.parentCode" :treeData="data" :replaceFields="replaceFields" style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择父级目录" allow-clear
          treeDefaultExpandAll></a-tree-select>
      </a-form-model-item>
    </a-modal>

    <!-- 修改文件目录 -->
    <!-- <a-modal :title="title" :visible="modalEdit" :confirm-loading="confirmLoading" @ok="handleOk"
      @cancel="handleCancel">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="文件夹" prop="fileName">
          <a-input v-model="form.fileName" allow-clear />
        </a-form-model-item>
      </a-form-model>

      <a-form-model-item label="父级目录" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select defaultExpandParent v-model="form.parentCode" :treeData="data" :replaceFields="replaceFields"
          style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择父级目录"
          allow-clear treeDefaultExpandAll></a-tree-select>
      </a-form-model-item>
    </a-modal> -->

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelModal" style="padding: 10px;">
      <img alt="example" style="width: 100%;" :src="previewImage" />
    </a-modal>

    <!-- 右键菜单 -->
    <ul v-show="visible" :style="{ left: left + 'px', top: top + 'px' }" class="contextmenu">
      <li @click="addFolder">新建目录</li>
      <li @click="editFolder">重命名</li>
      <li>删除</li>
    </ul>
  </div>
</template>

<script>
import { fileUrl } from "@/utils/url.js";

const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    align: "center",
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    }
  },
  {
    title: "文件名",
    dataIndex: "fileName",
    key: "fileName",
    align: "center",
    scopedSlots: { customRender: "fileName" },
    ellipsis: true
  },
  {
    title: "文件类型",
    dataIndex: "fileType",
    key: "fileType",
    align: "center",
    width: 100
  },
  {
    title: "上传人",
    dataIndex: "createName",
    key: "createName",
    align: "center",
    width: 150,
    ellipsis: true
  },
  {
    title: "上传时间",
    dataIndex: "createTime",
    key: "createTime",
    align: "center",
    width: 150,
    customRender: function (text) {
      return !text ? "" : text.length > 10 ? text.substr(0, 10) : text;
    },
    ellipsis: true
  },
  {
    title: "image",
    dataIndex: "",
    align: "center",
    scopedSlots: { customRender: "image" },
    width: 150
  },
  {
    title: "URL",
    dataIndex: "",
    align: "center",
    scopedSlots: { customRender: "url" },
    // width: 120
    ellipsis: true
  },
  {
    title: "操作",
    dataIndex: "",
    align: "center",
    scopedSlots: { customRender: "action" },
    width: 150
  }
];

export default {
  data() {
    return {
      columns,
      headers: {}, // 请求头
      btnDisabled: true, // 按钮禁用
      selectedFolder: {}, // 选中的目录
      uploadParams: {}, // 上传文件携带的参数：parentCode
      isLoading: false, // 加载状态
      fileArray: [], // 查询文件集合
      fileList: [], // 文件上传
      data: [], // 目录树

      folderFlag: 0, // 0：新增或修改目录，1：修改文件目录

      modalVisible: false, // 新增弹窗
      modalEdit: false, // 修改文件名称、文件目录弹窗

      title: "",
      confirmLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {}, // 表单数据
      isLoading: false,
      useFileUrl: `${process.env.VUE_APP_API_BASE_URL}/file`, // 上传文件地址
      previewVisible: false,
      previewImage: '', // 点击表格图片弹窗显示

      // 树key，title，children与数据集合关系对应
      replaceFields: {
        title: "fileName",
        key: "fileCode",
        children: "children",
        value: "fileCode"
      },

      // 分页参数
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30"],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },

      // 右键菜单属性
      visible: false,
      top: 0,
      left: 0,

      url: {
        add: "file/folder/insert",
        edit: "file/folder/update",
        selectFolderTree: "file/folder/selectFolderTree", // 文件夹树
        selectOne: "file/selectOne",
        pageList: "file/pageList", // 文件分页
        delete: "file/delete" // 文件删除
      },

      // 校验
      rules: {
        fileName: [
          {
            required: true,
            message: "请填写文件夹名！",
            trigger: "blur"
          }
        ]
      }
    };
  },
  watch: {
    visible(value) {
      if (value) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    }
  },
  created() {
    this.loadData();
    this.selectFileList();

    // 获取token，上传文件携带请求头
    // const token = this.$cookie.getCookie("token");
    // this.headers = { "X-Access-Token": token };
  },
  methods: {
    handleCancelModal() {
      this.previewVisible = false;
    },
    showImage(record) {
      this.previewImage = `${this.useFileUrl}${record.filePath}/${record.fileName}.${record.fileSuffix}`;
      this.previewVisible = true;
    },
    // 查询文件目录树
    loadData() {
      let params = {};
      this.$axios.get(fileUrl.selectFolderTree, { params }).then(res => {
        if (res.success) {
          this.data = res.result;
        }
        this.isLoading = false;
      });
    },
    // 查询文件列表
    selectFileList(val) {
      let params = {
        parentCode: val,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      };

      this.$axios.get(fileUrl.pageList, { params }).then(res => {
        if (res.success) {
          this.fileArray = res.result.records;
          this.pagination.total = res.result.total;
        }
        this.isLoading = false;
      });
    },
    // 分页切换
    handleTableChange(pagination, filters, sorter) {
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field;
        this.isorter.order = "ascend" == sorter.order ? "asc" : "desc";
      }
      this.pagination = pagination;
      this.selectFileList(this.selectedFolder.fileCode);
    },
    // 文件删除取消
    cancel(e) {
      console.log(e);
      this.$message.warning("取消删除！");
    },
    // 文件删除
    handleDelete(record) {
      // let params = {
      //   id: record.id,
      //   pathHead: record.pathHead,
      //   filePath: record.filePath,
      //   fileType: record.fileType,
      //   fileCode: record.fileCode,
      //   fileName: record.fileName,
      //   fileSuffix: record.fileSuffix
      // }

      // this.$axios.delete(fileUrl.delete, { params }).then(res => {
      //   if (res.success) {
      //     this.$message.success(res.message);
      //     this.selectFileList(record.parentCode);
      //   }
      //   this.isLoading = false;
      // });

      

      this.$axios.put(fileUrl.delete, record).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.selectFileList(record.parentCode);
        }
        this.isLoading = false;
      });

    },
    // 文件夹树选定
    onSelect(keys, event) {
      this.selectedFolder.fileCode = keys[0];
      this.selectedFolder.fileName = event.node.title;

      this.btnDisabled = false;
      console.log("Trigger Select", keys, event);

      this.selectFileList(keys[0]);
    },
    // 文件夹树展开
    onExpand() {
      console.log("Trigger Expand");
    },
    rightClick(event, node) {
      console.log(event, node);
    },
    // 自定义文件上传
    customRequest(data) {
      this.isLoading = true;

      const formData = new FormData();
      formData.append("file", data.file);
      const token = this.$cookie.getCookie("token");

      formData.append("X-Access-Token", token);
      formData.append("parentCode", this.selectedFolder.fileCode);

      let headers = { "Content-Type": "multipart/form-data" };

      this.$axios
        .post("file/upload", formData, { headers })
        .then(res => {
          if (res.success) {
            this.$message.success(res.message);
            this.btnDisabled = true;
            this.selectFileList(this.selectedFolder.fileCode);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    // 上传文件回调
    handleChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} 上传成功！`);
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} 文件上传失败！`);
      }
    },

    // 添加文件夹
    addFolder() {
      this.title = "新增目录";
      this.modalVisible = true;
    },
    // 修改文件夹
    editFolder() {
      this.title = "修改目录";
      this.modalVisible = true;

      let params = {
        fileCode: this.selectedFolder.fileCode
      };
      this.$axios.get(fileUrl.selectOne, { params }).then(res => {
        if (res.success) {
          this.form = res.result;
        }
        this.isLoading = false;
      });
    },
    // 提交
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.confirmLoading = true;

          let method = "";
          let url = "";

          if (!this.form.id) {
            method = "post";
            url = fileUrl.add;
          } else {
            method = "put";
            url = fileUrl.edit;
          }

          let options = {
            method: method,
            data: this.form,
            url: url
          };

          this.$axios(options)
            .then(res => {
              if (res.success) {
                this.$message.success(res.message);
                this.isLoading = false;
                this.handleCancel();
                this.loadData();
              }
            })
            .finally(() => {
              this.isLoading = false;
              this.confirmLoading = false;
              this.handleCancel();
            });
        } else {
          this.$message.warning("请填写表单！");
          return false;
        }
      });
    },
    // 取消
    handleCancel() {
      this.modalVisible = false;
      this.$refs.ruleForm.resetFields();
      this.form = {};

      this.btnDisabled = true;
    },

    // 打开编辑
    handleEdit(record) {
      this.folderFlag = 1;
      this.modalVisible = true;

      this.form = JSON.parse(JSON.stringify(record));
    },
    // 获取文件url
    getFileUrl(record) {
      const url = `${this.useFileUrl}${record.filePath}/${record.fileName}.${record.fileSuffix}`
      console.log('地址', url)
      window.open(url)
    },

    // 自定义鼠标右键菜单
    openMenu(e, item) {
      this.rightClickItem = item;

      //   if (item == 1) {
      //     this.left = e.offsetX;
      //     this.top = e.offsetY;
      //   } else if (item == 2) {
      //     this.left = e.offsetX + 200;
      //     this.top = e.offsetY + 30;
      //   }

      this.left = e.offsetX;
      this.top = e.offsetY;

      this.visible = true;
    },
    closeMenu() {
      this.visible = false;
    },
    rightClick(e) {
      console.log(e);
    }
  },
};
</script>

<style scoped>
/**文件操作栏 */
.file-tool-box {
  border-left: 1px solid rgb(232, 232, 232);
  border-right: 1px solid rgb(232, 232, 232);
  border-top: 1px solid rgb(232, 232, 232);
  min-height: 50px;
}

/**按钮 */
.btn-style {
  margin: 0px 5px 0 10px;
}

/**上传按钮 */
.upload-btn-style {
  float: left;
  margin: 0px 5px 0 10px;
}

/**文件搜索栏 */
.search-input-style {
  width: 200px;
  margin: 0px 5px 0 10px;
  /* margin: 10px 10px 0 0;
  float: right; */
}

/**文件列表：文件目录、文件列表 */
.file-table-style {
  border: 1px solid rgb(232, 232, 232);
}

/**文件夹树 */
/* .folder-tree-style {
     // height: 100%;
     border-right: 1px solid rgb(232, 232, 232);
     // min-height: 300px;
   } */
/**自定义表格头 */
.table-heard {
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-bottom: 1px solid rgb(232, 232, 232);
  background: rgb(239, 249, 255);
}

/**自定义表格内容 */
.table-content {
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-bottom: 1px solid rgb(232, 232, 232);
}

/**文字太长隐藏 */
.hidden-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**表格最后一行为空，美化样式 */
.table-last-row {
  height: 30px;
}

/**自定义鼠标右键菜单 */
.contextmenu {
  border: 1px solid rgb(232, 232, 232);
  margin: 0;
  background: #fff;
  /* z-index: 3000; */
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}

.contextmenu li {
  margin: 0;
  padding: 7px 16px;
  cursor: pointer;
}

.contextmenu li:hover {
  background: #eee;
}
</style>