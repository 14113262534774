import request from './request'


export const handleLogin = (params) => request.post('/sys/login', params);// 登录
export const handleLogout = (params) => request.post('/sys/logout', params);// 退出

/**
 * 客户
 */
export const handleCustomerInsert = (params) => request.post('/customer/insert', params);// 客户新增
export const handleCustomerUpdate = (params) => request.put('/customer/update', params);// 客户修改
export const handleCustomerDelete = (params) => request.delete('/customer/delete', params);// 客户删除
export const handleCustomerPageList = (params) => request.get('/customer/pageList', { params });// 客户分页
export const test = (method, url, params) => request(method, url, params);


/**
 * 博客
 */
export const handleBlogInsert = (params) => request.post('/blog/insert', params);// 博文新增
export const handleBlogUpdate = (params) => request.put('/blog/update', params);// 博文修改
export const handleBlogDelete = (params) => request.delete('/blog/delete', params);// 博文删除
export const handleBlogPageList = (params) => request.get('/blog/pageList', { params });// 博文分页


/**
 * 博客
 */
// 文章多语言新增
export const handleBlogLocaleInsert = (params) => request.post('/blogLocale/insert', params);
// 文章多语言修改
export const handleBlogLocaleUpdate = (params) => request.put('/blogLocale/update', params);
// 根据blogCode查询多语言
export const handleBlogLocaleList = (params) => request.get('/blogLocale/list', { params });



/**
 * 产品
 */
// 产品新增
export const handleProductInsert = (params) => request.post('/product/insert', params);
// 产品修改
export const handleProductUpdate = (params) => request.put('/product/update', params);


/**
 * 产品多语言
 */
// 产品多语言新增
export const handleProductLocaleInsert = (params) => request.post('/productLocale/insert', params);
// 产品多语言修改
export const handleProductLocaleUpdate = (params) => request.post('/productLocale/update', params);
// 根据productCode查询多语言产品详情
export const handleProductLocaleList = (params) => request.get('/productLocale/list', { params });



/**
 * 系统
 */
export const handleDictList = (params) => request.get('/dict/list', { params });// 字典列表




/**
 * 菜单
 */
export const treeMenuByUser = (params) => request.get('/menu/treeMenuByUser', { params });// 根据当前用户获取菜单

/**
 * 用户管理
 */
export const userList = (params) => request.get('/user/pageList', { params });// 获取用户列表
export const userDelete = (params) => request.delete('/user/delete', { params });// 删除用户
export const requestUpdatePassword = (params) => request.put('/user/updatePassword', { params });// 删除用户


/**
 * 暂时不用该接口
 * 文件管理
 */
// 文件夹新增
export const handleFolderInsert = (params) => request.post('/folder/insert', params);
// 文件夹修改
export const handleFolderUpdate = (params) => request.put('/folder/update', params);

// 文件删除
export const handleFileDelete = (params) => request.put("/file/delete", params);