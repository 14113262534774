<template>
  <a-modal
    :title="title"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="角色名" prop="roleName">
        <a-input v-model="form.roleName" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="角色标识" prop="remark">
        <a-input v-model="form.roleFlag" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="角色备注" prop="remark">
        <a-input v-model="form.remark" allow-clear />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      ModalText: "Content of the modal",
      visible: false,
      title: "",
      confirmLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },

      form: {}, // 表单数据
      isLoading: false,
      url: {
        add: "role/create",
        edit: "role/edit"
      },
      // 校验
      rules: {
        roleName: [
          {
            required: true,
            message: "请填写角色名！",
            trigger: "blur"
          }
        ]
        // remark: [
        //   {
        //     required: true,
        //     message: "请填写角色备注！",
        //     trigger: "blur"
        //   }
        // ]
      }
    };
  },
  created() {},
  methods: {
    // 新增
    add(data) {
      this.title = "新增角色";
      this.visible = true;
    },
    // 编辑
    edit(record) {
      this.title = "编辑角色";
      this.visible = true;
      this.form = JSON.parse(JSON.stringify(record));
    },
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.confirmLoading = true;

          let method = "";
          let url = "";

          if (!this.form.id) {
            method = "post";
            url = this.url.add;
          } else {
            method = "put";
            url = this.url.edit;
          }

          let options = {
            method: method,
            data: this.form,
            url: url
          };

          this.$axios(options)
            .then(res => {
              if (res.success) {
                this.$message.success(res.message);
                this.isLoading = false;
                this.handleCancel();
                this.$emit("refresh");
              }
            })
            .finally(() => {
              this.isLoading = false;
              this.confirmLoading = false;
              this.handleCancel();
            });
        } else {
          this.$message.warning("请填写表单！");
          return false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
      this.$refs.ruleForm.resetFields();
      this.form = {};
    }
  }
};
</script>

<style scoped>
</style>