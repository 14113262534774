<template>
  <div style="height: 100%;" class="background_style">
    <div class="login_box">
      <a-spin :spinning="isLoading" tip="登录中...">
        <a-form :form="form" class="login-form">
          <a-form-item>
            <a-input v-decorator="['phone', formRules.phone]" size="large" placeholder="手机号">
              <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-input v-decorator="['password', formRules.password]" size="large" type="password" placeholder="密码">
              <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-button type="primary" html-type="submit" size="large" class="login_button"
              @click="handleSubmit">登录</a-button>
          </a-form-item>
        </a-form>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { handleLogin } from "@/utils/api";

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      isLoading: false, // 是否为加载状态

      // 表单校验
      formRules: {
        phone: {
          rules: [{ required: true, message: "请输入手机号！" }]
        },
        password: {
          rules: [{ required: true, message: "请输入密码！" }]
        }
      }
    };
  },
  created() {
    // 已登陆状态不允许访问登陆页
    const token = this.$cookie.getCookie("token");
    if (token) {
      this.toHome();
    }
  },
  methods: {
    toHome() {
      this.$router.push({
        name: "home"
      });
    },
    handleSubmit(val) {
      console.log(this.form);
      this.form.validateFields((err, values) => {
        if (!err) {
          this.isLoading = true;
          let params = {
            phone: values.phone,
            password: values.password
          };

          handleLogin(params).then(res => {
            if (res.success) {
              let token = res.result.token;
              let user = res.result.user;

              this.$cookie.setCookie("token", token, 0);
              localStorage.setItem("user", JSON.stringify(user));

              this.toHome();

              this.$notification.open({
                message: "欢迎回来！",
                icon: "😀"
              });
            } else {
              this.isLoading = false;
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  min-width: 1200px;
}

/**背景样式 */
.background_style {
  background: #f0f2f5 url("../../assets/background.svg");
}

.login_box {
  width: 350px;
  height: 200px;
  /*margin: 0 auto;*/
  /*margin-top: 15%;*/
  margin-left: auto;
  margin-right: auto;

  /*position: absolute;*/
  position: relative;
  /*left: 35%;*/
  top: 35%;
}

.login_button {
  width: 100%;
}
</style>
