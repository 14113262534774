// 添加cookie
export function setCookie(objName, objValue, objHours) {//添加cookie
    var str = objName + "=" + escape(objValue) + ";path=/";

    // 为0时不设定过期时间，浏览器关闭时cookie自动消失
    if (objHours > 0) {
        var date = new Date();
        var ms = objHours * 3600 * 1000;
        date.setTime(date.getTime() + ms);
        str += "; expires=" + date.toGMTString();
    }
    document.cookie = str;
}

// 获取指定名称的cookie的值
export function getCookie(objName) {
    var arrStr = document.cookie.split(";");
    for (var i = 0; i < arrStr.length; i++) {
        var temp = arrStr[i].split("=");
        if (temp[0] == objName)
            return unescape(temp[1]);
    }
    // var arr, reg = new RegExp("(^| )" + objName + "=([^;]*)(;|$)");
    // if (arr = document.cookie.match(reg))
    //     return (arr[2]);
    // else
    //     return null;
}

// 为了删除指定名称的cookie，可以将其过期时间设定为一个过去的时间
export function deleteCookie(name) {
    var date = new Date();
    date.setTime(date.getTime() - 10000);
    document.cookie = name + "=a; expires=" + date.toGMTString() + ";path=/";
}

// 读取所有保存的cookie字符串
function allCookie() {
    var str = document.cookie;
    if (str == "") {
        str = "没有保存任何cookie";
    }
    alert(str);
}