<template>
  <a-drawer :title="title" width="720" :body-style="{ paddingBottom: '80px' }" placement="right" :closable="false"
    @close="onClose" :visible="visible">
    <a-spin :spinning="isLoading">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="类别" prop="category">
              <a-input v-model="form.category" placeholder="请输入字典类别" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="字典名称" prop="label">
              <a-input v-model="form.label" placeholder="请输入字典显示的名称" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="字典值" prop="value">
              <a-input v-model="form.value" placeholder="请输入字典值" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="字典描述" prop="description">
              <a-input v-model="form.description" placeholder="请输入字典描述" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="排序" prop="sortOrder">
              <a-input v-model="form.sortOrder" placeholder="请输入字典排序" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="状态" prop="status">
              <a-switch default-checked v-model="form.status" :title="form.status == false ? '已停用' : '已启用'" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-spin>
    <div :style="{
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    }">
      <a-button :style="{ marginRight: '8px' }" @click="onClose">取消</a-button>
      <a-button type="primary" @click="handleSubmit" :disabled="isLoading"
        :icon="isLoading ? 'loading' : ''">确定</a-button>
    </div>
  </a-drawer>
</template>

<script>
import { dictInsertUrl, dictUpdateUrl } from "@/utils/url";

export default {
  data() {
    //校验数字只能为整数
    let checkInteger = (rule, value, callback) => {
      setTimeout(() => {
        if (!(/^[0-9]\d*$/.test(value))) {
          callback(new Error('字典排序值只能为正整数'));
        } else {
          callback();
        }
      }, 100);
    };

    return {
      visible: false,
      title: "",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      isLoading: false,

      form: {}, // 表单数据

      // 校验
      rules: {
        category: [
          { required: true, message: "请输入字典类别！", trigger: "blur" },
        ],
        label: [
          { required: true, message: "请输入字典显示的名称！", trigger: "blur" },
        ],
        value: [
          { required: true, message: "请输入字典值！", trigger: "blur" },
        ],
        // description: [
        //   { required: true, message: "请输入字典描述！", trigger: "blur" }
        // ],
        sortOrder: [
          { required: true, message: "请输入字典排序！", trigger: "blur" },
          { validator: checkInteger }
        ],
        status: [
          { required: true, message: "请选择是否启用！", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    // 新增
    add(data) {
      this.title = "新增客户";
      this.visible = true;
    },
    // 编辑
    edit(record) {
      this.title = "编辑客户";
      this.visible = true;

      this.form = JSON.parse(JSON.stringify(record));
    },
    // 关闭抽屉
    onClose() {
      this.visible = false;
      this.$refs.ruleForm.resetFields();
      this.form = {};
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.isLoading = true;

          let method = "";
          let url = "";

          if (!this.form.id) {
            method = "post";
            url = dictInsertUrl;
          } else {
            method = "put";
            url = dictUpdateUrl;
          }

          let options = {
            method: method,
            data: this.form,
            url: url
          };

          this.$axios(options).then(res => {
            if (res.success) {
              this.$message.success(res.message);
              this.onClose();
              this.$emit("refresh");
            }
          })
            .finally(() => {
              this.isLoading = false;
              this.onClose();
            });
        } else {
          this.$message.warning("请填写表单！");
          return false;
        }
      });
    }
  },
};
</script>

<style scoped></style>