var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-common-style"},[_c('a-spin',{attrs:{"size":"large","spinning":_vm.isLoading,"tip":"文件上传中……"}},[_c('a-layout',{staticStyle:{"border":"1px solid rgb(232, 232, 232)"},attrs:{"id":"components-layout-demo-top-side-2"}},[_c('a-layout-header',{staticClass:"header",staticStyle:{"background":"#fff","border-bottom":"1px solid rgb(232, 232, 232)"}},[_c('a-row',{staticStyle:{"margin-left":"-50px"}},[_c('a-col',{attrs:{"span":24}},[_c('a-button',{staticClass:"btn-style",attrs:{"icon":"folder-add"},on:{"click":_vm.addFolder}},[_vm._v("创建目录")]),_c('a-button',{staticClass:"btn-style",attrs:{"icon":"edit","disabled":_vm.btnDisabled},on:{"click":_vm.editFolder}},[_vm._v("修改目录")]),_c('a-upload',{staticClass:"upload-btn-style",attrs:{"multiple":"","file-list":_vm.fileList,"customRequest":_vm.customRequest},on:{"change":_vm.handleChange}},[_c('a-button',{attrs:{"type":"primary","ghost":"","icon":"cloud-upload","disabled":_vm.btnDisabled}},[_vm._v("上传")])],1),_c('a-input-search',{staticClass:"search-input-style",attrs:{"placeholder":"搜索文件"}})],1)],1)],1),_c('a-layout',[_c('a-layout-sider',{staticStyle:{"background":"#fff","border-right":"1px solid rgb(232, 232, 232)"},attrs:{"width":"200"}},[_c('a-directory-tree',{attrs:{"default-expand-all":"","treeData":_vm.data,"replaceFields":_vm.replaceFields},on:{"select":_vm.onSelect,"expand":_vm.onExpand,"rightClick":_vm.openMenu}})],1),_c('a-layout',[_c('a-layout-content',{style:({
            background: '#fff',
            margin: 0,
            minHeight: '280px'
          })},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.fileArray,"pagination":_vm.pagination,"size":"middle"},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"image",fn:function(text, record){return _c('span',{},[_c('a-avatar',{attrs:{"shape":"square","size":64,"src":`${_vm.useFileUrl}${record.filePath}/${record.fileName}.${record.fileSuffix}`},on:{"click":function($event){return _vm.showImage(record)}}})],1)}},{key:"url",fn:function(text, record){return _c('span',{},[_c('a-tooltip',{attrs:{"placement":"topLeft"}},[_c('template',{slot:"title"},[_c('span',[_vm._v(_vm._s(_vm.useFileUrl)+_vm._s(record.filePath)+"/"+_vm._s(record.fileName)+"."+_vm._s(record.fileSuffix))])]),_c('a',[_vm._v(_vm._s(`${_vm.useFileUrl}${record.filePath}/${record.fileName}.${record.fileSuffix}`))])],2)],1)}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a',{on:{"click":function($event){return _vm.getFileUrl(record)}}},[_vm._v("查看")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{on:{"click":function($event){return _vm.handleEdit(record)}}},[_vm._v("编辑")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a-popconfirm',{attrs:{"title":"确定删除？","ok-text":"是","cancel-text":"否"},on:{"confirm":function($event){return _vm.handleDelete(record)},"cancel":_vm.cancel}},[_c('a',[_vm._v("删除")])])],1)}}])})],1)],1)],1)],1),_c('div',{style:({
      right: 0,
      bottom: 0,
      width: '100%',
      padding: '10px 16px',
      background: '#fff',
      zIndex: 1
    })})],1),_c('a-modal',{attrs:{"title":_vm.title,"visible":_vm.modalVisible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[(_vm.folderFlag == 0)?_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-form-model-item',{attrs:{"label":"文件夹","prop":"fileName"}},[_c('a-input',{attrs:{"allow-clear":""},model:{value:(_vm.form.fileName),callback:function ($$v) {_vm.$set(_vm.form, "fileName", $$v)},expression:"form.fileName"}})],1)],1):_vm._e(),(_vm.folderFlag == 1)?_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-form-model-item',{attrs:{"label":"文件名","prop":"fileName"}},[_vm._v(" "+_vm._s(_vm.form.fileName)+" ")])],1):_vm._e(),_c('a-form-model-item',{attrs:{"label":"父级目录","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-tree-select',{staticStyle:{"width":"100%"},attrs:{"treeData":_vm.data,"replaceFields":_vm.replaceFields,"dropdown-style":{ maxHeight: '400px', overflow: 'auto' },"placeholder":"请选择父级目录","allow-clear":"","treeDefaultExpandAll":""},model:{value:(_vm.form.parentCode),callback:function ($$v) {_vm.$set(_vm.form, "parentCode", $$v)},expression:"form.parentCode"}})],1)],1),_c('a-modal',{staticStyle:{"padding":"10px"},attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancelModal}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"contextmenu",style:({ left: _vm.left + 'px', top: _vm.top + 'px' })},[_c('li',{on:{"click":_vm.addFolder}},[_vm._v("新建目录")]),_c('li',{on:{"click":_vm.editFolder}},[_vm._v("重命名")]),_c('li',[_vm._v("删除")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }