<template>
  <a-drawer :title="title" width="720" :body-style="{ paddingBottom: '80px' }" placement="right" :closable="false"
    @close="onClose" :visible="visible">
    <a-spin :spinning="isLoading">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="公司名" prop="customerName">
              <a-input v-model="form.customerName" placeholder="请输入公司名称或客户名" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="行业" prop="industry">
              <a-input v-model="form.industry" placeholder="请输入客户所属行业" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="联系人" prop="name">
              <a-input v-model="form.name" placeholder="请输入联系人名字" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="性别" prop="sex">
              <a-radio-group name="radioGroup" v-model="form.sex">
                <a-radio value="1">
                  <a-icon type="man" />&nbsp;&nbsp;男
                </a-radio>
                <a-radio value="0">
                  <a-icon type="woman" />&nbsp;&nbsp;女
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="电话" prop="phone">
              <a-input v-model="form.phone" placeholder="请输入联系电话" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="邮箱" prop="email">
              <a-input v-model="form.email" placeholder="请输入邮箱地址" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="网站" prop="website">
              <a-input v-model="form.website" placeholder="请输入客户网站" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="地址" prop="address">
              <a-input v-model="form.address" placeholder="请输入客户地址" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row> -->
        <!-- <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="客户来源" prop="channel" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-radio-group name="radioGroup" v-model="form.channel">
                <a-radio value="0"> 搜索引擎 </a-radio>
                <a-radio value="1"> 官网 </a-radio>
                <a-radio value="2"> 社交媒体 </a-radio>
                <a-radio value="3"> 阿里巴巴 </a-radio>
                <a-radio value="4"> 其他 </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row> -->
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="网站" prop="website" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-input v-model="form.website" allow-clear> </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="地址" prop="address" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-input v-model="form.address" allow-clear> </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-spin>
    <div :style="{
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    }">
      <a-button :style="{ marginRight: '8px' }" @click="onClose">取消</a-button>
      <a-button type="primary" @click="handleSubmit" :disabled="isLoading"
        :icon="isLoading ? 'loading' : ''">确定</a-button>
    </div>
  </a-drawer>
</template>

<script>
import { customerInsertUrl, customerUpdateUrl } from "@/utils/url";

export default {
  data() {
    return {
      visible: false,
      title: "",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      isLoading: false,

      form: {}, // 表单数据

      // 校验
      rules: {
        customerName: [
          { required: true, message: "请填写客户名称！", trigger: "blur" },
        ],
        business: [
          { required: true, message: "请填写主营产品！", trigger: "blur" },
        ],
        channel: [
          { required: true, message: "请选择客户来源！", trigger: "change" },
        ],
        // phone: [
        //   { required: true, message: "请填写电话！", trigger: "blur" }
        // ],
        email: [
          { required: true, message: "请选择邮箱！", trigger: "blur" }
        ],
        // website: [
        //   { required: true, message: "请选择客户网站！", trigger: "blur" },
        // ],
        // address: [
        //   { required: true, message: "请选择客户地址！", trigger: "blur" },
        // ],
      },
    };
  },
  methods: {
    // 新增
    add(data) {
      this.title = "新增客户";
      this.visible = true;
    },
    // 编辑
    edit(record) {
      this.title = "编辑客户";
      this.visible = true;

      this.form = JSON.parse(JSON.stringify(record));
    },
    // 关闭抽屉
    onClose() {
      this.visible = false;
      this.$refs.ruleForm.resetFields();
      this.form = {};
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.isLoading = true;

          let method = "";
          let url = "";

          if (!this.form.id) {
            method = "post";
            url = customerInsertUrl;
          } else {
            method = "put";
            url = customerUpdateUrl;
          }

          let options = {
            method: method,
            data: this.form,
            url: url
          };

          this.$axios(options).then(res => {
            if (res.success) {
              this.$message.success(res.message);
              this.onClose();
              this.$emit("refresh");
            }
          })
            .finally(() => {
              this.isLoading = false;
              this.onClose();
            });
        } else {
          this.$message.warning("请填写表单！");
          return false;
        }
      });
    }
  },
};
</script>

<style scoped></style>