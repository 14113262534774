<template>
  <a-spin :spinning="isLoading">
  <a-modal :title="title" :visible="visible" :maskClosable="false" @ok="handleOk" @cancel="handleCancel"
    :dialog-style="{ top: '20px' }" width="80%">
    <!-- <a-spin :spinning="isLoading"> -->
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <!-- 产品多种语言描述 -->
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="语言" prop="locale">
              <a-select v-model="form.locale" @change="handleChange" placeholder="请选择语言">
                <a-select-option value="jack" v-for="(item, index) in localeDictList" :key="index" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="产品标题" prop="productTitle">
              <a-input v-model="form.productTitle" placeholder="请输入产品标题" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="毛质" prop="hair">
              <a-input v-model="form.hair" placeholder="请输入产品标题" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="口管" prop="ferrule">
              <a-input v-model="form.ferrule" placeholder="请输入口管材质" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="刷柄" prop="handle">
              <a-input v-model="form.handle" placeholder="请输入刷柄材质" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="包装" prop="packagingType">
              <a-input v-model="form.packagingType" placeholder="请输入包装方式" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 产品详情 -->
        <a-row :gutter="20">
          <a-col :span="24">
            <MyEditor ref="editorComponent" v-model='content' @editorContent='contentChange' :editData="content" />
          </a-col>
        </a-row>
      </a-form-model>
    <!-- </a-spin> -->
  </a-modal>
</a-spin>

</template>

<script>
import { productInsertUrl, productUpdateUrl, dictListUrl } from "@/utils/url";
import { handleProductLocaleInsert, handleProductLocaleUpdate } from "@/utils/api";
import MyEditor from "@/components/MyEditor";

export default {
  components: {
    MyEditor
  },
  data() {
    return {
      /**
       * 弹窗相关
       */
      visible: false, // 弹窗是否打开
      title: '',


      isLoading: false,
      labelCol: { span: 3 },
      wrapperCol: { span: 21 },


      /**
       * 数据初始化
       */
      form: {}, // 表单数据
      content: '', // 编辑器数据
      localeDictList: [],


      // 校验
      rules: {
        locale: [
          { required: true, message: "请选择语言！", trigger: "change" },
        ],
        productTitle: [
          { required: true, message: "请填写产品标题！", trigger: "blur" },
        ],
        hair: [
          { required: true, message: "请填写毛质！", trigger: "blur" },
        ],
        ferrule: [
          { required: true, message: "请填写口管材质！", trigger: "blur" },
        ],
        handle: [
          { required: true, message: "请填写手柄材质！", trigger: "blur" },
        ],
        packagingType: [
          { required: true, message: "请填写包装方式！", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请填写产品描述！", trigger: "blur" }
        ]
      },
    };
  },
  methods: {
    // 查询国家字典列表
    queryLocaleDictList() {
      let params = {
        category: "locale"
      };

      this.$axios.get(dictListUrl, { params }).then(res => {
        if (res.success) {
          this.localeDictList = res.result;
        }
      });
    },
    // 产品分类多选
    handleChange(value) {
      this.form.productI18nEntity.locale = value;
    },
    add(record) {
      this.visible = true;
      this.title = "新增产品多语言详情";

      this.queryLocaleDictList(); // 查询国际化

      this.form = {};
      this.$nextTick(() => {
        this.$refs.editorComponent.updateContent(''); // 清空富文本编辑器的内容
        this.content = '';
        this.$refs.editorComponent.token = this.$cookie.getCookie('token');
      });

      // 判断产品存在就可以向产品多语言描述中新增
      if (record.id != undefined && record.id != '' && record.id != null) {
        this.form.productCode = record.productCode;
      }
    },
    edit(record) {
      this.visible = true;
      this.title = "修改产品多语言详情";
// debugger
      this.queryLocaleDictList(); // 查询国际化字典
      
      this.content = record.productDetail; // 富文本处理
      this.form = record;

      this.$nextTick(() => {
        this.$refs.editorComponent.updateContent(record.productDetail);
      });
    },
    // 关闭弹窗
    handleCancel() {
      this.form = {};
      this.content = '';
      this.$nextTick(() => {
        this.$refs.editorComponent.updateContent(''); // 清空富文本编辑器的内容
      });
      this.visible = false;
    },
    contentChange(data) {
      console.log("编辑器数据::", data)
      this.content = data
    },
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.isLoading = true;

          this.form.productDetail = this.content;

          
          if (!this.form.id) {
            this.addSubmit(this.form);
          } else {
            this.editSubmit(this.form);
          }


          // let method = "";
          // let url = "";

          // if (!this.form.id) {
          //   method = "post";
          //   url = productInsertUrl;
          // } else {
          //   method = "put";
          //   url = productUpdateUrl;
          // }

          // let options = {
          //   method: method,
          //   data: this.form,
          //   url: url
          // };


          // this.$axios(options).then(res => {
          //   if (res.success) {
          //     this.$message.success(res.message);
          //     this.onClose();
          //     this.$emit("refresh");
          //   }
          // })
          //   .finally(() => {
          //     this.isLoading = false;
          //     this.onClose();
          //   });
        } else {
          this.$message.warning("请填写表单！");
          return false;
        }
      })
    },
    addSubmit(params) {
      handleProductLocaleInsert(params).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.handleCancel();
          this.$emit("refresh");
        }
      }).finally(() => {
        this.isLoading = false;
        this.handleCancel();
      });
    },
    editSubmit(params) {
      handleProductLocaleUpdate(params).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.handleCancel();
          this.$emit("refresh");
        }
      }).finally(() => {
        this.isLoading = false;
        this.handleCancel();
      });
    }
  }
}  
</script>

<style scoped></style>