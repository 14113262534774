<template>
    <div style="border: 1px solid #ccc;">
        <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
        <Editor style="height: 500px; overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" :mode="mode"
            @input="handleInput" @onCreated="onCreated" />
    </div>
</template>

<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default Vue.extend({
    components: { Editor, Toolbar },
    // props: { editData: String },
    data() {
        return {
            editor: null,
            // html: this.editData,
            html: '',
            toolbarConfig: {},
            uploadFile: null,
            token: '',
            testdata: '嘻嘻',
            editorConfig: {
                placeholder: '请输入内容...',
                MENU_CONF: {
                    // 配置上传图片
                    uploadImage: {
                        fieldName: 'file',
                        // 后端上传地址，必填
                        server: `${process.env.VUE_APP_API_BASE_URL}/api/file/editorUpload`,
                        headers: { "X-Access-Token": this.$cookie.getCookie("token") },
                        



                        // 上传之前触发
                        onBeforeUpload(file) {
                            // this.uploadFile = file
                            return file
                        },
                        // 单个文件上传成功之后
                        onSuccess(file, res) {

                        },
                        // 自定义上传
                        // async customUpload(file, insertFn) {
                        //     // insertFn(`${process.env.VUE_APP_API_BASE_URL}/file/upload`, poster)

                        //     const formData = new FormData();
                        //     formData.append("file", file);
                        //     // debugger
                        //     // console.log('……………………………………66', token)
                        //     const token = this.$cookie.getCookie("token");

                        //     formData.append("X-Access-Token", this.token);

                        //     let headers = { "Content-Type": "multipart/form-data" };

                        //     this.$axios.post(`${process.env.VUE_APP_API_BASE_URL}/file/upload`, formData, { headers }).then(res => {
                        //         if (res.success) {
                        //             this.$message.success(res.message);
                        //             let fileUrl = `${process.env.VUE_APP_API_BASE_URL}/file${res.result.filePath}`;
                        //             // insertFn(url, "使用说明", url); //插入图片

                        //             insertFn(fileUrl, '', fileUrl)
                        //         }
                        //     }).finally(() => {
                        //         this.isLoadingUpload = false;
                        //     });
                        // },

                        meta: {
                        },
                    },
                },
            },
            mode: 'default', // or 'simple'
        }
    },
    // computed: {
    //     tokenFromCookie() {
    //         return this.$cookie.getCookie('token');
    //     }
    // },
    // created() {
    //     this.token = this.tokenFromCookie;
    //     console.log("tokenFromCookie::==========", this.token)
    // },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        handleInput(value) {
            console.log("编辑器内容handleInput::", value)
            this.$emit("editorContent", value); // 将内容同步到父组件中
        },
        updateContent(data) {
            // debugger
            if (data == null || data === '' || data === undefined) {
                this.html = '';
                return;
            }
            this.$nextTick(() => {
                console.log("执行前====updateContent::", this.html)
                this.html = data;
                console.log("执行后====updateContent::", this.html)
            });
        },
    },
    mounted() {
        // 模拟 ajax 请求，异步渲染编辑器
        // setTimeout(() => {
        //     this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
        // }, 1500)
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
})
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>