<template>
  <a-modal
    :visible="visible"
    :width="900"
    :keyboard="false"
    :closable="false"
    :centered="true"
    @ok="ok"
    @cancel="cancel"
    :maskClosable="false"
    :mask="false"
    okText="确认"
    cancelText="取消"
  >
    <a-tabs>
      <a-tab-pane tab="方向性图标" key="1">
        <ul>
          <li v-for="icon in icons.directionIcons" :key="icon">
            <a-icon
              :type="icon"
              :title="icon"
              @click="chooseIcon(icon)"
              :class="{'active':activeIndex === icon}"
            />
          </li>
        </ul>
      </a-tab-pane>
      <a-tab-pane tab="指示性图标" key="2">
        <ul>
          <li v-for="icon in icons.suggestionIcons" :key="icon">
            <a-icon
              :type="icon"
              :title="icon"
              @click="chooseIcon(icon)"
              :class="{'active':activeIndex === icon}"
            />
          </li>
        </ul>
      </a-tab-pane>
      <a-tab-pane tab="编辑类图标" key="3">
        <ul>
          <li v-for="icon in icons.editIcons" :key="icon">
            <a-icon
              :type="icon"
              :title="icon"
              @click="chooseIcon(icon)"
              :class="{'active':activeIndex === icon}"
            />
          </li>
        </ul>
      </a-tab-pane>
      <a-tab-pane tab="数据类图标" key="4">
        <ul>
          <li v-for="icon in icons.dataIcons" :key="icon">
            <a-icon
              :type="icon"
              :title="icon"
              @click="chooseIcon(icon)"
              :class="{'active':activeIndex === icon}"
            />
          </li>
        </ul>
      </a-tab-pane>
      <a-tab-pane tab="网站通用图标" key="5">
        <ul>
          <li v-for="icon in icons.webIcons" :key="icon">
            <a-icon
              :type="icon"
              :title="icon"
              @click="chooseIcon(icon)"
              :class="{'active':activeIndex === icon}"
            />
          </li>
        </ul>
      </a-tab-pane>
      <a-tab-pane tab="品牌和标识" key="6">
        <ul>
          <li v-for="icon in icons.logoIcons" :key="icon">
            <a-icon
              :type="icon"
              :title="icon"
              @click="chooseIcon(icon)"
              :class="{'active':activeIndex === icon}"
            />
          </li>
        </ul>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>
<script>
// 方向性图标
const directionIcons = [
  "step-backward",
  "step-forward",
  "fast-backward",
  "fast-forward",
  "shrink",
  "arrows-alt",
  "down",
  "up",
  "left",
  "right",
  "caret-up",
  "caret-down",
  "caret-left",
  "caret-right",
  "up-circle",
  "down-circle",
  "left-circle",
  "right-circle",
  "double-right",
  "double-left",
  "vertical-left",
  "vertical-right",
  "vertical-align-top",
  "vertical-align-middle",
  "vertical-align-bottom",
  "forward",
  "backward",
  "rollback",
  "enter",
  "retweet",
  "swap",
  "swap-left",
  "swap-right",
  "arrow-up",
  "arrow-down",
  "arrow-left",
  "arrow-right",
  "play-circle",
  "up-square",
  "down-square",
  "left-square",
  "right-square",
  "login",
  "logout",
  "menu-fold",
  "menu-unfold",
  "border-bottom",
  "border-horizontal",
  "border-inner",
  "border-outer",
  "border-left",
  "border-right",
  "border-top",
  "border-verticle",
  "pic-center",
  "pic-left",
  "pic-right",
  "radius-bottomleft",
  "radius-bottomright",
  "radius-upleft",
  "radius-upright",
  "fullscreen",
  "fullscreen-exit"
];

// 提示建议性图标
const suggestionIcons = [
  "question",
  "question-circle",
  "plus",
  "plus-circle",
  "pause",
  "pause-circle",
  "minus",
  "minus-circle",
  "plus-square",
  "minus-square",
  "info",
  "info-circle",
  "exclamation",
  "exclamation-circle",
  "close",
  "close-circle",
  "close-square",
  "check",
  "check-circle",
  "check-square",
  "clock-circle",
  "warning",
  "issues-close",
  "st"
];

// 编辑类图标
const editIcons = [
  "edit",
  "form",
  "copy",
  "scissor",
  "delete",
  "snippets",
  "diff",
  "highlight",
  "align-center",
  "align-left",
  "align-right",
  "bg-colors",
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "redo",
  "undo",
  "zoom-in",
  "zoom-out",
  "font-colors",
  "font-size",
  "line-height",
  "dash",
  "small-dash",
  "sort-ascending",
  "sort-descending",
  "drag",
  "ordered-list",
  "unordered-list",
  "radius-setting",
  "column-width",
  "column-height"
];

// 数据类图标
const dataIcons = [
  "area-chart",
  "pie-chart",
  "bar-chart",
  "dot-chart",
  "line-chart",
  "radar-chart",
  "heat-map",
  "fall",
  "rise",
  "stock",
  "box-plot",
  "fund",
  "sliders"
];

// 网站通用图标
const webIcons = [
  "account-book",
  "alert",
  "api",
  "appstore",
  "audio",
  "bank",
  "bell",
  "book",
  "bug",
  "bulb",
  "calculator",
  "build",
  "calendar",
  "camera",
  "car",
  "carry-out",
  "cloud",
  "code",
  "compass",
  "contacts",
  "container",
  "control",
  "credit-card",
  "crown",
  "customer-service",
  "dashboard",
  "database",
  "dislike",
  "environment",
  "experiment",
  "eye-invisible",
  "eye",
  "file-add",
  "file-excel",
  "file-exclamation",
  "file-image",
  "file-markdown",
  "file-pdf",
  "file-ppt",
  "file-text",
  "file-unknown",
  "file-word",
  "file-zip",
  "file",
  "filter",
  "fire",
  "flag",
  "folder-add",
  "folder",
  "folder-open",
  "frown",
  "funnel-plot",
  "gift",
  "hdd",
  "heart",
  "home",
  "hourglass",
  "idcard",
  "insurance",
  "interaction",
  "layout",
  "like",
  "lock",
  "mail",
  "medicine-box",
  "meh",
  "message",
  "mobile",
  "money-collect",
  "pay-circle",
  "notification",
  "phone",
  "picture",
  "play-square",
  "printer",
  "profile",
  "project",
  "pushpin",
  "property-safety",
  "read",
  "reconciliation",
  "red-envelope",
  "rest",
  "rocket",
  "safety-certificate",
  "save",
  "schedule",
  "security-scan",
  "setting",
  "shop",
  "shopping",
  "skin",
  "smile",
  "sound",
  "star",
  "switcher",
  "tablet",
  "tag",
  "tags",
  "tool",
  "thunderbolt",
  "trophy",
  "unlock",
  "usb",
  "video-camera",
  "wallet",
  "apartment",
  "audit",
  "barcode",
  "bars",
  "block",
  "border",
  "branches",
  "ci",
  "cloud-download",
  "cloud-server",
  "cloud-sync",
  "cloud-upload",
  "cluster",
  "coffee",
  "copyright",
  "deployment-unit",
  "desktop",
  "disconnect",
  "dollar",
  "download",
  "ellipsis",
  "euro",
  "exception",
  "export",
  "file-done",
  "file-jpg",
  "file-protect",
  "file-sync",
  "file-search",
  "fork",
  "gateway",
  "global",
  "gold",
  "history",
  "import",
  "inbox",
  "key",
  "laptop",
  "link",
  "line",
  "loading-3-quarters",
  "loading",
  "man",
  "menu",
  "monitor",
  "more",
  "number",
  "percentage",
  "paper-clip",
  "pound",
  "poweroff",
  "pull-request",
  "qrcode",
  "reload",
  "safety",
  "robot",
  "scan",
  "search",
  "select",
  "shake",
  "share-alt",
  "shopping-cart",
  "solution",
  "sync",
  "table",
  "team",
  "to-top",
  "trademark",
  "transaction",
  "upload",
  "user-add",
  "user-delete",
  "usergroup-add",
  "user",
  "usergroup-delete",
  "wifi",
  "woman"
];

// 品牌和标识
const logoIcons = [
  "android",
  "apple",
  "windows",
  "ie",
  "chrome",
  "github",
  "aliwangwang",
  "dingding",
  "weibo-square",
  "weibo-circle",
  "taobao-circle",
  "html5",
  "weibo",
  "twitter",
  "wechat",
  "youtube",
  "alipay-circle",
  "taobao",
  "skype",
  "qq",
  "medium-workmark",
  "gitlab",
  "medium",
  "linkedin",
  "google-plus",
  "dropbox",
  "facebook",
  "codepen",
  "code-sandbox",
  "amazon",
  "google",
  "codepen-circle",
  "alipay",
  "ant-design",
  "ant-cloud",
  "aliyun",
  "zhihu",
  "slack",
  "slack-square",
  "behance",
  "behance-square",
  "dribbble",
  "dribbble-square",
  "instagram",
  "yuque",
  "alibaba",
  "yahoo",
  "reddit",
  "sketch"
];

export default {
  name: "Icons",
  props: {
    iconChooseVisible: {
      default: false
    }
  },
  data() {
    return {
      visible: false,
      icons: {
        directionIcons,
        suggestionIcons,
        editIcons,
        dataIcons,
        webIcons,
        logoIcons
      },
      choosedIcon: "",
      activeIndex: ""
    };
  },
  computed: {
    show: {
      get: function() {
        return this.iconChooseVisible;
      },
      set: function() {}
    }
  },
  methods: {
    // 打开选择图标页面
    openIcons() {
      this.visible = true;
    },
    reset() {
      this.activeIndex = "";
    },
    chooseIcon(icon) {
      this.activeIndex = icon;
      this.choosedIcon = icon;
      this.$message.success(`选中 ${icon}`);
    },
    ok() {
      if (this.choosedIcon === "") {
        this.$message.warning("尚未选择任何图标");
        return;
      }
      this.reset();
      this.$emit("choose", this.choosedIcon);
    },
    cancel() {
      this.reset();
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
ul {
  max-height: 700px;
  overflow-y: auto;
  padding-left: 0.5rem;
}
ul i {
  font-size: 1.5rem;
  border: 1px solid #f1f1f1;
  padding: 0.2rem;
  margin: 0.3rem;
  cursor: pointer;
}
ul i.active,
ul i:hover {
  border-radius: 2px;
  border-color: #4a4a48;
  background-color: #4a4a48;
  color: #fff;
  transition: all 0.3s;
}
ul li {
  list-style: none;
  float: left;
  width: 5%;
  text-align: center;
  cursor: pointer;
  color: #555;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  position: relative;
  margin: 3px 0;
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  padding: 10px 0 0;
}
</style>
