<template>
  <div class="page-common-style">
    <div class="handle-bar-style">
      <a-row :gutter="16">
        <a-col class="gutter-row" :span="24">
          <a-button type="primary" icon="plus" @click="handleAdd">新增</a-button>
        </a-col>
      </a-row>
    </div>
    
    <a-spin :spinning="isLoading">
      <a-table
        bordered
        :columns="columns"
        rowKey="id"
        :data-source="data"
        size="middle"
        :pagination="false"
      >
        <span slot="icon" slot-scope="icon">
          <a-icon :type="icon"></a-icon>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定删除？"
            ok-text="是"
            cancel-text="否"
            @confirm="handleDelete(record.id)"
            @cancel="cancel"
          >
            <a>删除</a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-spin>

    <!-- 添加菜单组件 -->
    <MenuAdd ref="menuAddDom" @refresh="selectMenuList"></MenuAdd>
  </div>
</template>

<script>
import MenuAdd from "@/views/sys/menu/MenuAdd";

const columns = [
  {
    title: "菜单名称",
    dataIndex: "menuName",
    key: "menuName",
    align: "left"
  },
  {
    title: "菜单类型",
    dataIndex: "menuType",
    key: "menuType",
    align: "center",
    customRender: function(text) {
      if (text == 0) {
        return "一级菜单";
      } else if (text == 1) {
        return "子菜单";
      } else if (text == 2) {
        return "按钮/权限";
      } else {
        return text;
      }
    }
  },
  {
    title: "组件名",
    dataIndex: "componentName",
    key: "componentName",
    align: "center"
  },
  {
    title: "icon",
    dataIndex: "icon",
    key: "icon",
    align: "center",
    scopedSlots: { customRender: "icon" }
  },
  { title: "排序", dataIndex: "sort", key: "sort", align: "center" },
  {
    title: "操作",
    dataIndex: "",
    align: "center",
    scopedSlots: { customRender: "action" },
    width: 120
  }
];

export default {
  components: {
    MenuAdd
  },
  data() {
    return {
      data: [],
      columns,
      isLoading: false // 是否为加载状态
    };
  },
  created() {
    this.selectMenuList();
  },
  methods: {
    // 查询菜单列表
    selectMenuList() {
      this.isLoading = true;

      this.$axios.get("menu/list", {}).then(res => {
        if (res.success) {
          this.data = res.result;
        }
        this.isLoading = false;
      });
    },
    // 打开新增
    handleAdd() {
      this.$refs.menuAddDom.add(this.data);
    },
    // 打开编辑
    handleEdit(record) {
      this.$refs.menuAddDom.menuTreeList = this.data;
      this.$refs.menuAddDom.edit(record);
    },
    // 菜单删除
    handleDelete(val) {
      this.isLoading = true;
      this.$axios.delete("menu/delete", { params: { id: val } }).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.selectMenuList();
        }
        this.isLoading = false;
      });
    },
    cancel(e) {
      console.log(e);
      this.$message.warning("删除取消！");
    }
  }
};
</script>

<style scoped>
</style>
