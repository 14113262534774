var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"title":"角色授权","width":"600","body-style":{ paddingBottom: '80px' },"placement":"right","closable":false,"visible":_vm.visible,"afterVisibleChange":_vm.afterVisibleChange},on:{"close":_vm.onClose}},[_c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('a-tree',{attrs:{"checkable":"","checkStrictly":"","defaultExpandAll":"","checkedKeys":_vm.checkedMenuCode,"replaceFields":_vm.replaceFields,"tree-data":_vm.treeData,"defaultCheckedKeys":_vm.checkedMenuCode},on:{"check":_vm.onCheck}})],1),_c('div',{style:({
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '100%',
    borderTop: '1px solid #e9e9e9',
    padding: '10px 16px',
    background: '#fff',
    textAlign: 'right',
    zIndex: 1,
  })},[_c('a-button',{style:({ marginRight: '8px' }),on:{"click":_vm.onClose}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","disabled":_vm.buttonIsLoading,"icon":_vm.buttonIsLoading ? 'loading' : ''},on:{"click":_vm.handleSubmit}},[_vm._v("确定")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }