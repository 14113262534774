<template>
  <div class="page-common-style">
    <div class="handle-bar-style">
      <a-row :gutter="16">
        <a-col class="gutter-row" :span="24">
          <a-button type="primary" icon="plus" @click="handleAdd">新增</a-button>
        </a-col>
      </a-row>
    </div>

    <a-spin :spinning="isLoading">
      <a-table bordered :columns="columns" rowKey="id" :data-source="dataSource" size="middle" :pagination="pagination">
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定删除？" ok-text="是" cancel-text="否" @confirm="handleDelete(record.id)" @cancel="cancel">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-spin>

    <customer-form ref="handleFrom" @refresh="loadData" />
  </div>
</template>

<script>
import { customerPageListUrl, customerDeleteUrl } from "@/utils/url.js";
import CustomerForm from "@/views/biz/customer/CustomerForm.vue";

const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    align: "center",
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "客户名称",
    dataIndex: "customerName",
    key: "customerName",
    align: "center",
  },
  {
    title: "行业",
    dataIndex: "industry",
    key: "industry",
    align: "center",
  },
  {
    title: "联系人",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "性别",
    dataIndex: "sex",
    key: "sex",
    align: "center",
    customRender: function (text) {
      if (text == 0) {
        return "女";
      } else if (text == 1) {
        return "男";
      }
    }
  },
  {
    title: "电话",
    dataIndex: "phone",
    key: "phone",
    align: "center",
  },
  {
    title: "邮箱",
    dataIndex: "email",
    key: "email",
    align: "center",
  },
  {
    title: "网站",
    dataIndex: "website",
    key: "website",
    align: "center",
  },
  {
    title: "公司地址",
    dataIndex: "address",
    key: "address",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "",
    align: "center",
    scopedSlots: { customRender: "action" },
    width: 120,
  },
];
export default {
  components: {
    CustomerForm,
  },
  data() {
    return {
      columns,
      isLoading: false, // 是否为加载状态
      dataSource: [], // 表格数据

      // 分页参数
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30"],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // 查询列表
    loadData() {
      this.isLoading = true;

      let params = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };

      this.$axios.get(customerPageListUrl, { params }).then((res) => {
        if (res.success) {
          this.dataSource = res.result.records;
          this.pagination.total = res.result.total;
        }
        this.isLoading = false;
      });
    },
    handleAdd() {
      this.$refs.handleFrom.add();
    },
    // 打开新增
    handleAdd() {
      this.$refs.handleFrom.add();
    },
    // 打开编辑
    handleEdit(record) {
      this.$refs.handleFrom.edit(record);
    },
    // 删除取消
    cancel(e) {
      console.log(e);
      this.$message.warning("取消删除！");
    },
    // 删除
    handleDelete(val) {
      this.isLoading = true;

      let params = {
        id: val,
      };

      this.$axios.delete(customerDeleteUrl, { params }).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.loadData();
        }
        this.isLoading = false;
      });
    }
  },
};
</script>

<style scoped></style>