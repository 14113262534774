<template>
  <div class="page-common-style">
    <div class="handle-bar-style">
      <a-row :gutter="16">
        <a-col class="gutter-row" :span="24">
          <a-button type="primary" icon="plus" @click="handleAdd">新增</a-button>
        </a-col>
      </a-row>
    </div>

    <a-spin :spinning="isLoading">
      <a-table
        bordered
        :columns="columns"
        rowKey="id"
        :data-source="data"
        size="middle"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定删除？"
            ok-text="是"
            cancel-text="否"
            @confirm="handleDelete(record)"
            @cancel="cancel"
          >
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a @click="handleAuthorization(record)">授权</a>
        </template>
      </a-table>
    </a-spin>

    <role-add ref="roleAddDom" @refresh="loadData" />
    <role-menu ref="roleMenuDom" @refresh="loadData" />
  </div>
</template>

<script>
import RoleAdd from "@/views/sys/role/RoleAdd";
import RoleMenu from "@/views/sys/role/RoleMenu";

const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    align: "center",
    customRender: function(t, r, index) {
      return parseInt(index) + 1;
    }
  },
  {
    title: "角色名称",
    dataIndex: "roleName",
    key: "roleName",
    align: "center"
  },
  {
    title: "角色标识",
    dataIndex: "roleFlag",
    key: "roleFlag",
    align: "center"
  },
  {
    title: "所属公司  ",
    dataIndex: "cpyName",
    key: "cpyName",
    align: "center"
  },
  { title: "备注", dataIndex: "remark", key: "remark", align: "center" },
  {
    title: "创建人",
    dataIndex: "createName",
    key: "createName",
    align: "center"
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
    align: "center",
    customRender: function(text) {
      return !text ? "" : text.length > 10 ? text.substr(0, 10) : text;
    }
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
    width: 200
  }
];

export default {
  components: {
    RoleAdd,
    RoleMenu
  },
  data() {
    return {
      isLoading: false, // 是否为加载状态
      data: [],
      columns,
      // 分页参数
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30"],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      url: {
        pageList: "role/pageList",
        delete: "role/delete"
      }
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.isLoading = true;

      let params = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      };

      this.$axios.get(this.url.pageList, { params }).then(res => {
        if (res.success) {
          this.data = res.result.records;
          this.pagination.total = res.result.total;
        }
        this.isLoading = false;
      });
    },
    // 打开新增
    handleAdd() {
      this.$refs.roleAddDom.add();
    },
    // 打开编辑
    handleEdit(record) {
      this.$refs.roleAddDom.menuTreeList = this.data;
      this.$refs.roleAddDom.edit(record);
    },
    // 分页切换
    handleTableChange(pagination, filters, sorter) {
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field;
        this.isorter.order = "ascend" == sorter.order ? "asc" : "desc";
      }
      this.pagination = pagination;
      this.loadData();
    },
    // 删除取消
    cancel(e) {
      console.log(e);
      this.$message.warning("取消删除！");
    },
    // 删除
    handleDelete(val) {
      this.isLoading = true;
      
      this.$axios.put(this.url.delete, val).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.loadData();
        }
        this.isLoading = false;
      });
    },
    // 打开授权
    handleAuthorization(record) {
      this.$refs.roleMenuDom.add(record);
    }
  }
};
</script>

<style scoped>
</style>