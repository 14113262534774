<template>
  <a-drawer title="角色授权" width="600" :body-style="{ paddingBottom: '80px' }" placement="right" :closable="false"
    @close="onClose" :visible="visible" :afterVisibleChange="afterVisibleChange">
    <a-spin :spinning="isLoading">
      <a-tree checkable checkStrictly defaultExpandAll :checkedKeys="checkedMenuCode" :replaceFields="replaceFields"
        :tree-data="treeData" :defaultCheckedKeys="checkedMenuCode" @check="onCheck" />
    </a-spin>
    <div :style="{
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    }">
      <a-button :style="{ marginRight: '8px' }" @click="onClose">取消</a-button>
      <a-button type="primary" @click="handleSubmit" :disabled="buttonIsLoading"
        :icon="buttonIsLoading ? 'loading' : ''">确定</a-button>
    </div>
  </a-drawer>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      isLoading: false, // 是否为加载状态
      buttonIsLoading: false, // 是否为加载状态
      roleCode: "", // 角色code

      // 树
      treeData: [],
      checkedMenuCode: [], // 勾选的节点
      // 菜单树key，title，children与数据集合关系对应
      replaceFields: {
        title: "menuName",
        key: "menuCode",
        children: "children"
      },

      expandedKeys: [], // 展开指定的树节点
      autoExpandParent: true,
      selectedKeys: [],

      url: {
        list: "menu/list",
        roleMenuCreate: "rolemenu/create",
        queryMenuByRoleCode: "rolemenu/queryMenuByRoleCode"
      }
    };
  },
  created() { },
  methods: {
    // 查询树形菜单列表
    loadData() {
      this.isLoading = true;

      this.$axios.get(this.url.list, {}).then(res => {
        if (res.success) {
          this.treeData = res.result;
        }
        this.isLoading = false;
      });
    },
    // 根据角色code查询菜单
    selectMenuByRoleCode() {
      let params = {
        roleCode: this.roleCode
      };

      this.$axios.get(this.url.queryMenuByRoleCode, { params }).then(res => {
        if (res.success) {
          if (res.result[0] != null) {
            this.checkedMenuCode = res.result;
          }
        }
        this.isLoading = false;
      });
    },
    add(record) {
      this.roleCode = record.roleCode;
      this.loadData();
      this.selectMenuByRoleCode();
      this.visible = true;
    },
    onClose() {
      this.visible = false;
      this.isLoading = false;
      this.buttonIsLoading = false;
      this.checkedMenuCode = [];
    },
    // 提交
    handleSubmit() {
      this.isLoading = true;
      this.buttonIsLoading = true;
      // this.roleMenuList = [];

      // for (let i in this.checkedMenuCode.checked) {
      //   let rm = {
      //     roleCode: this.roleCode,
      //     menuCode: this.checkedMenuCode.checked[i]
      //   };
      //   this.roleMenuList.push(rm);
      // }

      let params = {
        roleCode: this.roleCode,
        menuCode: this.checkedMenuCode.join(",")
        // menuCodeList: this.roleMenuList
      };

      debugger;

      this.$axios
        .post(this.url.roleMenuCreate, params)
        .then(res => {
          if (res.success) {
            this.$message.success(res.message);
            this.onClose();
            this.$emit("refresh");
          }
          this.isLoading = false;
          this.buttonIsLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
          this.buttonIsLoading = false;
          this.onClose();
        });
    },
    afterVisibleChange() { },
    // -------------------------------------
    // 展开/收起节点时触发
    onExpand(expandedKeys) {
      console.log("onExpand", expandedKeys);
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    // 勾选时触发
    onCheck(checkedKeys) {
      debugger;
      this.checkedMenuCode = checkedKeys.checked;
      // this.checkedMenuCode = checkedKeys;
    },
    // 点击文字时触发
    onSelect(selectedKeys, info) {
      console.log("onSelect", info);
      debugger;
      this.selectedKeys = selectedKeys;
    }
  }
};
</script>

<style scoped></style>