<template>
  <a-spin :spinning="isLoading">
  <a-modal :title="title" :visible="visible" :maskClosable="false" @ok="handleOk" @cancel="handleCancel"
    :dialog-style="{ top: '20px' }" width="80%">
    <!-- <a-spin :spinning="isLoading"> -->
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <!-- 文章多种语言描述 -->
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="语言" prop="locale">
              <a-select v-model="form.locale" placeholder="请选择语言">
                <a-select-option value="jack" v-for="(item, index) in localeDictList" :key="index" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="文章标题" prop="blogTitle">
              <a-input v-model="form.blogTitle" placeholder="请输入文章标题" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 文章详情 -->
        <a-row :gutter="20">
          <a-col :span="24">
            <MyEditor ref="editorComponent" v-model='content' @editorContent='contentChange' :editData="content" />
          </a-col>
        </a-row>
      </a-form-model>
    <!-- </a-spin> -->
  </a-modal>
</a-spin>

</template>

<script>
import { handleBlogLocaleInsert, handleBlogLocaleUpdate, handleDictList } from "@/utils/api";
import MyEditor from "@/components/MyEditor";

export default {
  components: {
    MyEditor
  },
  data() {
    return {
      /**
       * 弹窗相关
       */
      visible: false, // 弹窗是否打开
      title: '',


      isLoading: false,
      labelCol: { span: 3 },
      wrapperCol: { span: 21 },


      /**
       * 数据初始化
       */
      form: {}, // 表单数据
      content: '', // 编辑器数据
      localeDictList: [],


      // 校验
      rules: {
        locale: [
          { required: true, message: "请选择语言！", trigger: "change" },
        ],
        blogTitle: [
          { required: true, message: "请填写文章标题！", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请填写文章描述！", trigger: "blur" }
        ]
      },
    };
  },
  methods: {
    // 查询国家字典列表
    queryLocaleDictList() {
      let params = {
        category: "locale"
      };

      handleDictList(params).then(res => {
        if (res.success) {
          this.localeDictList = res.result;
        }
      });
    },
    add(record) {
      this.visible = true;
      this.title = "新增多语种文章";

      this.queryLocaleDictList(); // 查询国际化

      this.form = {};
      this.$nextTick(() => {
        this.$refs.editorComponent.updateContent(''); // 清空富文本编辑器的内容
        this.content = '';
        this.$refs.editorComponent.token = this.$cookie.getCookie('token');
      });

      // 判断文章存在就可以向文章多语言描述中新增
      if (record.id != undefined && record.id != '' && record.id != null) {
        this.form.blogCode = record.blogCode;
      }
    },
    edit(record) {
      this.visible = true;
      this.title = "修改多语种文章";
// debugger
      this.queryLocaleDictList(); // 查询国际化字典
      
      this.content = record.blogContent; // 富文本处理
      this.form = record;

      this.$nextTick(() => {
        this.$refs.editorComponent.updateContent(record.blogContent);
      });
    },
    // 关闭弹窗
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.content = '';
      this.$nextTick(() => {
        this.$refs.editorComponent.updateContent(''); // 清空富文本编辑器的内容
      });
      this.visible = false;
    },
    contentChange(data) {
      console.log("编辑器数据::", data)
      this.content = data
    },
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.isLoading = true;

          this.form.blogContent = this.content;

          if (!this.form.id) {
            this.addSubmit(this.form);
          } else {
            this.editSubmit(this.form);
          }
        } else {
          this.$message.warning("请填写表单！");
          return false;
        }
      })
    },
    addSubmit(params) {
      handleBlogLocaleInsert(params).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.handleCancel();
          this.$emit("refresh");
        }
      }).finally(() => {
        this.isLoading = false;
        this.handleCancel();
      });
    },
    editSubmit(params) {
      handleBlogLocaleUpdate(params).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.handleCancel();
          this.$emit("refresh");
        }
      }).finally(() => {
        this.isLoading = false;
        this.handleCancel();
      });
    }
  }
}  
</script>

<style scoped></style>