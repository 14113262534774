import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from '@/utils/request'
import Antd from 'ant-design-vue'; // 引入ant-design
import 'ant-design-vue/dist/antd.css';
import { setCookie, getCookie, deleteCookie } from "./utils/cookie.js"; // 封装cookie

// 解释：https://www.cnblogs.com/itgezhu/p/11949621.html
Vue.config.productionTip = false

// 配置导航守卫，拦截前端路由
router.beforeEach((to, from, next) => {
  // 判断该路由是否需要登录权限
  if (to.meta.requireAuth) {
    const token = Vue.prototype.$cookie.getCookie("token");

    // token不为空，表示登陆成功，放行
    if (token) {
      next();
    } else {
      next({
        path: '/',
        query: { redirect: to.fullPath }  // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next();
  }
});

// 手机号校验
let judgePhone = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[0-9])\d{8}$/;
Vue.prototype.$MOBILE_PATTERN = judgePhone;

// 日期格式化
Vue.filter("formatDate", function formatDate(value) {
  var date = new Date(value);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return year + "-" + month + "-" + day;
});
 
// 日期时间格式化过滤器（2019-12-17 15:31）
Vue.filter("formatDateTime", function formatDateTime(value) {
  var date = new Date(value);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return year + "-" + month + "-" + day + " " + hours + ":" + minutes;
});

// 添加使用cookie
Vue.prototype.$cookie = {
  setCookie,
  getCookie,
  deleteCookie
};

Vue.prototype.$axios = axios; // 将 Axios 实例绑定到 Vue 的原型上

Vue.use(Antd);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
