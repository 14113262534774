<template>
  <a-layout id="components-layout-demo-custom-trigger" style="min-height: 100vh">
    <!-- 左侧菜单 -->
    <a-layout-sider :trigger="null" v-model="collapsed" collapsible breakpoint="lg">
      <div class="logo" />
      <a-menu theme="dark" mode="inline" :defaultSelectedKeys="['1']">
        <template v-for="m1 in menuList">
          <!-- 一级菜单下无子菜单 -->
          <a-menu-item :key="m1.id" v-if="!m1.children" @click="handleTabs(m1)">
            <a-icon :type="m1.icon" />
            <span>{{ m1.menuName }}</span>
          </a-menu-item>

          <!-- 一级菜单下有子菜单 -->
          <a-sub-menu :key="m1.id" v-if="m1.children">
            <span slot="title">
              <a-icon :type="m1.icon" />
              <span class="nav-text">{{ m1.menuName }}</span>
            </span>

            <template v-for="m2 in m1.children">
              <!-- 二级菜单下无三级菜单 -->
              <a-menu-item :key="m2.id" v-if="!m2.children" @click="handleTabs(m2)">
                <a-icon :type="m2.icon" />
                <span class="nav-text">{{ m2.menuName }}</span>
              </a-menu-item>

              <!-- 二级菜单下有三级菜单-->
              <a-sub-menu :key="m2.id" v-if="m2.children">
                <span slot="title">
                  <a-icon :type="m2.icon" />
                  <span class="nav-text">{{ m2.menuName }}</span>
                </span>

                <!--显示三级菜单-->
                <a-menu-item :key="m3.id" v-for="m3 in m2.children" @click="handleTabs(m3)">
                  <a-icon :type="m3.icon" />
                  <span class="nav-text">{{ m3.menuName }}</span>
                </a-menu-item>
              </a-sub-menu>
            </template>
          </a-sub-menu>
        </template>
      </a-menu>
    </a-layout-sider>

    <a-layout>
      <!-- 头部 -->
      <a-layout-header class="layout-header-style">
        <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)" />

        <div class="user-info-div-style">
          <div class="user-info">
            <a-dropdown @click="e => e.preventDefault()">
              <a class="ant-dropdown-link">
                <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                  style="margin-top: -3px;" />
                <span>{{ userInfo.name }}</span>
              </a>
              <a-menu slot="overlay" style="margin-top: 20px;">
                <a-menu-item>
                  <!-- <a href="javascript:;">修改密码</a> -->
                  <a-button type="link" @click="handleUpdatePassword">修改密码</a-button>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          <div class="logout-div-style">
            <a-button type="link" icon="logout" @click="handleLogout">退出</a-button>
          </div>
        </div>
      </a-layout-header>

      <!-- 主内容区 -->
      <a-layout-content class="layout-content-style">
        <a-tabs hideAdd v-model="activeKey" type="editable-card" size="small" class="tabs-style" @edit="onEdit">
          <!-- 多标签页 -->
          <a-tab-pane v-for="pane in panes" :tab="pane.title" :key="pane.key" :closable="pane.closable">
            <component :is="pane.content"></component>
          </a-tab-pane>
        </a-tabs>
      </a-layout-content>
    </a-layout>

    <a-modal
    title="修改密码"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="原密码" prop="oldPassword">
        <a-input-password  v-model="form.oldPassword" placeholder="请输入原密码" />
      </a-form-model-item>
      <a-form-model-item label="新密码" prop="newPassword">
        <a-input-password v-model="form.newPassword" placeholder="请输入新密码" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
  </a-layout>
</template>

<script>
import MainPage from "@/views/sys/tools/MainPage.vue"; // 首页

import CustomerList from "@/views/biz/customer/CustomerList.vue"; // 客户列表
import EnquiryList from "@/views/biz/enquiry/EnquiryList.vue"; // 客户列表
import ProductList from "@/views/biz/product/ProductList.vue"; //产品列表
import BlogList from "@/views/biz/blog/BlogList.vue"; //文章列表

// 系统管理
import UserList from "@/views/sys/user/UserList.vue"; // 用户列表
import DictList from "@/views/sys/dict/DictList.vue";
import MenuList from "@/views/sys/menu/MenuList.vue"; // 菜单列表
import RoleList from "@/views/sys/role/RoleList.vue"; // 角色列表
import FileList from "@/views/sys/file/FileList.vue"; // 文件列表
import FileList2 from "@/views/sys/file/FileList2.vue"; // 文件列表2

// 系统工具
import SwaggerUI from "@/views/sys/tools/SwaggerUI.vue"; // swagger接口文档

import { requestUpdatePassword } from "@/utils/api";

export default {
  components: {
    MainPage,

    CustomerList,
    EnquiryList,
    ProductList,
    BlogList,

    DictList,
    UserList,
    MenuList,
    RoleList,
    FileList,
    FileList2,

    SwaggerUI
  },
  data() {
    return {
      collapsed: false, // 菜单栏是否折叠
      userInfo: {}, // 用户信息

      menuList: [], // 菜单集合
      panes: [],
      activeKey: 0,

      visible: false, // 修改密码弹窗
      form: {},
      confirmLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      // 校验
      rules: {
        oldPassword: [
          {
            required: true,
            message: "请填写原密码！",
            trigger: "blur"
          }
        ],
        newPassword: [
          {
            required: true,
            message: "请填写新密码！",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log('======查看登录用户：', user);
    this.userInfo = user;

    // 默认打开首页
    this.panes.push({
      title: "首页",
      content: "MainPage",
      key: 0,
      closable: false
    });

    let params = {
      phone: user.phone
    };

    this.$axios.get("menu/treeMenuByUser", { params }).then(res => {
      if (res.success) {
        this.menuList = res.result;
      }
    });
  },
  methods: {
    // 退出登陆
    handleLogout() {
      let that = this;

      this.$confirm({
        title: "提示",
        content: "确定要退出吗？",
        okText: "确定",
        cancelText: "取消",
        onCancel() { },
        onOk() {
          that.$axios.post("sys/logout", {}).then(res => {
            if (res.success) {
              that.$message.success(res.message);
              that.$cookie.deleteCookie("token");
              that.$router.push({
                name: "login"
              });
            }
          });
        }
      });
    },
    // 点击菜单打开页签
    handleTabs(val) {
      // 页签存在的情况下不能打开
      for (let item of this.panes) {
        if (val.componentName == item.content) {
          this.activeKey = item.key;
          return this.$message.warning(`${val.menuName}页面已打开！`);
        }
      }

      this.panes.push({
        title: val.menuName,
        content: val.componentName,
        key: val.id
      });

      this.activeKey = val.id; // 激活当前打开的页签
    },
    // 关闭页签
    remove(targetKey) {
      let activeKey = this.activeKey;
      let lastIndex;
      this.panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });

      const panes = this.panes.filter(pane => pane.key !== targetKey);
      if (panes.length && activeKey === targetKey) {
        if (lastIndex >= 0) {
          activeKey = panes[lastIndex].key;
        } else {
          activeKey = panes[0].key;
        }
      }
      this.panes = panes;
      this.activeKey = activeKey;
    },
    callback(key) {
      console.log(key);
    },
    onEdit(targetKey, action) {
      this[action](targetKey);
    },

    // 修改密码
    handleUpdatePassword() {
      this.visible = true;
    },
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.confirmLoading = true;

          let params = {
            userCode: this.userInfo.userCode,
            oldPassword: this.form.oldPassword,
            newPassword: this.form.newPassword
          }

          requestUpdatePassword(params).then(res => {
            if (res.success) {
              this.$message.success(res.message);
              this.handleCancel();
              this.$emit("refresh");
            }
          }).finally(() => {
            this.confirmLoading = false;
            this.handleCancel();
          });

        } else {
          this.$message.warning("请填写表单！");
          return false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
      this.$refs.ruleForm.resetFields();
      this.form = {};
    }
  }
};
</script>

<style scoped>
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

/**收缩按钮颜色 */
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

/**--------------------------------------------------------------- */

/**主内容区，即：tabs多标签页样式 */
.layout-content-style {
  /*margin: 24px 16px;*/
  /*padding: 24px;*/
  /* background: rgb(238, 191, 191); */
  /* margin: 15px; */
  background: #fff;
}

/**tab标签样式 */
.tabs-style {
  margin-left: 1px;
  margin-top: -1px;
  /* margin-bottom: 50px; */
}

/**--------------------------------------------------------------- */

/**头部样式 */
.layout-header-style {
  background: #fff;
  padding: 0;
  border-bottom: solid 1px rgb(232, 232, 232);
}

/**--------------------------------------------------------------- */

/**退出按钮鼠标悬停样式 */
.hover-style:hover {
  /* background: rgb(225, 248, 255); */
  /* background: rgba(233, 230, 230, 0.3); */
  background: rgba(250, 250, 250);
}

.user-info-div-style {
  float: right;
  height: 63px;
}

/**头像、姓名 */
.user-info {
  height: 63px;
  float: left;
  padding: 0px 10px 0px 10px;
}

.user-info:hover {
  background: rgba(250, 250, 250);
}

/**退出 */
.logout-div-style {
  height: 63px;
  float: left;
}

.logout-div-style:hover {
  background: rgba(250, 250, 250);
}
</style>
