<template>
  <a-drawer :title="title" width="720" :body-style="{ paddingBottom: '80px' }" placement="right" :closable="false"
    @close="onClose" :visible="visible">
    <a-spin :spinning="isLoading">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="姓名" prop="name">
              <a-input v-model="form.name" allow-clear>
                <a-icon slot="prefix" type="user" />
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="性别" prop="sex">
              <a-radio-group name="radioGroup" v-model="form.sex">
                <a-radio value="1">
                  <a-icon type="man" />&nbsp;&nbsp;男
                </a-radio>
                <a-radio value="0">
                  <a-icon type="woman" />&nbsp;&nbsp;女
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="电话" prop="phone">
              <a-input v-model="form.phone" placeholder="手机号作为登陆账号" allow-clear>
                <a-icon slot="prefix" type="phone" />
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="邮箱">
              <a-input v-model="form.email" allow-clear>
                <a-icon slot="prefix" type="mail" />
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="微信">
              <a-input v-model="form.wechat" allow-clear>
                <a-icon slot="prefix" type="wechat" />
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="QQ">
              <a-input v-model="form.qq" allow-clear>
                <a-icon slot="prefix" type="qq" />
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="身份证" prop="idCard">
              <a-input v-model="form.idCard" allow-clear>
                <a-icon slot="prefix" type="idcard" />
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="生日" prop="birthday">
              <a-date-picker v-model="form.birthday" format="YYYY-MM-DD" allow-clear style="width: 100%;" />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="籍贯" prop="idCardAddr" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-input v-model="form.idCardAddr" allow-clear style="width: 100%;">
                <a-icon slot="prefix" type="flag" />
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="现居地" prop="liveAddr" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-input v-model="form.liveAddr" allow-clear style="width: 100%">
                <a-icon slot="prefix" type="environment" />
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 企业管理员userType为1，权限是最大的，只有平台才能给企业管理员授权 -->
        <a-row :gutter="20" v-if="form.userType != 1">
          <a-col :span="24">
            <a-form-model-item label="角色分配" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-select mode="multiple" :default-value="existingRole" style="width: 100%" placeholder="请选择用户角色"
                v-model="form.roles" @change="handleChange">
                <a-select-option v-for="(item, index) in roleList" :key="index" :value="item.roleCode">{{ item.roleName
                  }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="账号状态" prop="status">
              <!-- <a-radio-group name="radioGroup" v-model="form.status">
                <a-radio value="1">启用</a-radio>
                <a-radio value="0">停用</a-radio>
              </a-radio-group>-->
              <a-switch default-checked v-model="form.status" :title="form.status == false ? '已停用' : '已启用'" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-spin>

    <div :style="{
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    }">
      <a-button :style="{ marginRight: '8px' }" @click="onClose">取消</a-button>
      <a-button type="primary" @click="handleSubmit" :disabled="isLoading"
        :icon="isLoading ? 'loading' : ''">确定</a-button>
    </div>
  </a-drawer>
</template>

<script>
export default {
  data() {
    // 校验身份证
    let checkIdCard = (rule, value, callback) => {
      setTimeout(() => {
        if (
          !/^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/.test(
            value)
        ) {
          callback(new Error("请输入正确的身份证号"));
        } else {
          callback();
        }
      }, 100);
    };

    return {
      visible: false,
      title: "",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      isLoading: false,

      form: {}, // 表单数据
      roleList: [], // 角色集合
      existingRole: [], // 用户已有的角色
      userInfo: {}, // 用户信息

      url: {
        roleList: "role/list",
        add: "user/insert",
        edit: "user/update",
        userRoleList: "user/role/queryUserRole"
      },

      // 校验
      rules: {
        name: [{ required: true, message: "请填写姓名！", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别！", trigger: "change" }],
        phone: [{ required: true, message: "请填写手机号！", trigger: "blur" }],
        idCard: [
          { required: true, message: "请填写身份证！", trigger: "blur" },
          { validator: checkIdCard }
        ],
        birthday: [
          { required: true, message: "请选择生日！", trigger: "change" }
        ],
        idCardAddr: [
          { required: true, message: "请填写户籍所在地！", trigger: "blur" }
        ],
        liveAddr: [
          { required: true, message: "请填写居住地！", trigger: "blur" }
        ],
        status: [
          { required: true, message: "请选择账号状态！", trigger: "change" }
        ]
      }
    };
  },
  created() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.userInfo.userType = user.userType;
  },
  methods: {
    // 新增
    add(data) {
      this.title = "新增用户";
      this.visible = true;
      this.queryRoleList();
    },
    // 编辑
    edit(record) {
      this.title = "编辑用户";
      this.visible = true;

      this.queryRoleList();
      this.queryUserRole(record);

      this.form = JSON.parse(JSON.stringify(record));
    },
    // 关闭抽屉
    onClose() {
      this.visible = false;
      this.$refs.ruleForm.resetFields();
      this.form = {};
    },
    // 查询角色集合
    queryRoleList() {
      let params = {};

      this.$axios.get(this.url.roleList, { params }).then(res => {
        if (res.success) {
          this.roleList = res.result;
        }
      });
    },
    // 查询用户角色
    queryUserRole(record) {
      let params = {
        userCode: record.userCode
      };

      this.$axios.get(this.url.userRoleList, { params }).then(res => {
        if (res.success) {
          this.form.roles = res.result;
        }
      });
    },
    // select多选
    handleChange(value) {
      debugger;
      // this.form.role = JSON.stringify(value);
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.isLoading = true;

          let method = "";
          let url = "";

          if (!this.form.id) {
            method = "post";
            url = this.url.add;
          } else {
            method = "put";
            url = this.url.edit;
          }

          let options = {
            method: method,
            data: this.form,
            url: url
          };

          this.$axios(options)
            .then(res => {
              if (res.success) {
                this.$message.success(res.message);
                this.onClose();
                this.$emit("refresh");
              }
            })
            .finally(() => {
              this.isLoading = false;
              this.onClose();
            });
        } else {
          this.$message.warning("请填写表单！");
          return false;
        }
      });
    }
  }
};
</script>

<style scoped></style>