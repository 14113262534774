<template>
  <div>首页</div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style scoped>
</style>