<template>
  <a-drawer :title="title" width="600" :body-style="{ paddingBottom: '80px' }" placement="right" :closable="false"
    @close="onClose" :visible="visible" :afterVisibleChange="afterVisibleChange">
    <a-spin :spinning="isLoading">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="菜单类型">
          <a-radio-group @change="onChangeMenuType" name="radioGroup"
            v-decorator="['menuType', { rules: [{ required: true, message: '请选择菜单类型！' }] }]">
            <a-radio value="0">一级菜单</a-radio>
            <a-radio value="1">子菜单</a-radio>
            <a-radio value="2">按钮</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="上级菜单" v-if="menuObj.menuType == 1 || menuObj.menuType == 2">
          <a-tree-select v-decorator="[
            'parentCode',
            { rules: [{ required: true, message: '请选择上级菜单！' }] },
          ]" style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" allow-clear>
            <a-tree-select-node :key="item.id" :value="item.menuCode" :title="item.menuName"
              v-for="item in menuTreeList">
              <a-tree-select-node :key="c.id" :value="c.menuCode" :title="c.menuName" v-for="c in item.children">
                <a-tree-select-node :key="c2.id" :value="c2.menuCode" :title="c2.menuName"
                  v-for="c2 in c.children"></a-tree-select-node>
              </a-tree-select-node>
            </a-tree-select-node>
          </a-tree-select>
        </a-form-item>
        <a-form-item label="菜单名称">
          <a-input v-decorator="['menuName', validatorRules.menuType]" />
        </a-form-item>
        <a-form-item label="组件名" v-if="menuObj.menuType != 2">
          <a-input v-decorator="['componentName', validatorRules.componentName]" />
        </a-form-item>
        <a-form-item label="路由路径" v-if="menuObj.menuType != 2">
          <a-input v-decorator="['componentPath', validatorRules.componentPath]" />
        </a-form-item>
        <a-form-item label="权限标识">
          <a-input v-decorator="['permissions', validatorRules.permissions]" />
        </a-form-item>
        <a-form-item label="排序">
          <a-input v-decorator="['sort', validatorRules.sort]" />
        </a-form-item>
        <a-form-item label="icon" v-if="menuObj.menuType != 2">
          <a-input v-decorator="['icon', validatorRules.icon]">
            <!-- <a-icon slot="prefix" type="user" /> -->
            <a-icon slot="addonAfter" type="setting" @click="selectIcons" />
          </a-input>
        </a-form-item>
        <!-- <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button :style="{ marginRight: '8px' }" @click="onClose">关闭</a-button>
          <a-button type="primary" html-type="确定" @click="handleSubmit">Submit</a-button>
        </a-form-item>-->
      </a-form>
    </a-spin>
    <div :style="{
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    }">
      <a-button :style="{ marginRight: '8px' }" @click="onClose">取消</a-button>
      <a-button type="primary" @click="handleSubmit" :disabled="isLoading"
        :icon="isLoading ? 'loading' : ''">确定</a-button>
    </div>

    <Icons ref="iconsDom"></Icons>
  </a-drawer>
</template>

<script>
import Icons from "@/views/common/Icons";

export default {
  components: {
    Icons
  },
  data() {
    return {
      visible: false,
      title: "",
      form: this.$form.createForm(this), // 表单
      isLoading: false, // 是否为加载状态

      menuTreeList: [], // 菜单树集合
      menuObj: {}, // 菜单实体

      url: {
        add: "menu/create",
        update: "menu/update"
      },

      validatorRules: {
        menuType: {
          rules: [{ required: true, message: "请选择菜单类型！" }]
        },
        parentCode: {
          rules: [{ required: true, message: "请选择上级菜单！" }]
        },
        menuName: {
          rules: [{ required: true, message: "请填写菜单名称！" }]
        },
        componentName: {
          // rules: [{ required: true, message: "请填写组件名！" }]
        },
        permissions: {
          // rules: [{ required: true, message: "请填写权限！" }]
        },
        componentPath: {
          // rules: [{ required: true, message: "请填写组件路径！" }]
        },
        sort: {
          // rules: [{ required: true, message: "请填写菜单排序！" }]
        },
        icon: {
          // rules: [{ required: true, message: "请填写菜单图标！" }]
        }
      }
    };
  },
  created() { },
  methods: {
    afterVisibleChange(val) {
      console.log("visible", val);
    },
    // 关闭抽屉
    onClose() {
      this.form.resetFields();
      this.visible = false;
      this.menuObj = {};
    },
    // 菜单类型动态加载表单
    onChangeMenuType(e) {
      this.menuObj.menuType = e.target.value;
    },
    // 新增菜单
    add(data) {
      this.menuTreeList = data;
      this.title = "新增菜单";
      this.visible = true;
    },
    // 编辑
    edit(record) {
      this.title = "编辑菜单";
      this.visible = true;

      this.form.resetFields();
      // this.model = Object.assign({}, record);

      // 列表传入的对象转成json字符串，再转为对象，避免修改时与列表行为通一对象，提交为成功时改变页面显示的值
      this.menuObj = JSON.parse(JSON.stringify(record));

      // 将值设置到表单中
      this.$nextTick(() => {
        this.form.setFieldsValue(record);
      });
    },
    // 选择菜单
    selectIcons() {
      this.$refs.iconsDom.openIcons();
    },
    // 提交
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // let params = this.form.getFieldsValue();
          this.isLoading = true;

          let method = "";
          let url = "";

          let params = Object.assign(this.menuObj, values);
          if (!this.menuObj.id) {
            method = "post";
            url = this.url.add;
          } else {
            method = "put";
            url = this.url.update;
          }

          this.$axios({
            method: method,
            data: params,
            url: url
          }).then(res => {
            if (res.success) {
              this.$message.success(res.message);
              this.isLoading = false;
              this.onClose();
              this.$emit("refresh");
            }
          })
            .finally(() => {
              this.isLoading = false;
              this.onClose();
            });
        }
      });
    }
  }
};
</script>

<style scoped></style>