<template>
  <div class="page-common-style">
    <div class="handle-bar-style">
      <a-row :gutter="16">
        <a-col class="gutter-row" :span="24">
          <a-button type="primary" icon="plus" @click="handleAdd">新增</a-button>
        </a-col>
      </a-row>
    </div>

    <a-spin :spinning="isLoading">
      <a-table bordered :columns="columns" rowKey="id" :data-source="dataSource" size="middle" :pagination="pagination" @change="handleTableChange">
        <span slot="status" slot-scope="status">
          <a-tag :color="status == false ? 'red' : 'green'">{{ status == false ? '已停用' : '已启用' }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定删除？" ok-text="是" cancel-text="否" @confirm="handleDelete(record.id)" @cancel="cancel">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-spin>

    <dict-form ref="handleFrom" @refresh="loadData" />
  </div>
</template>

<script>
import { dictPageListUrl, dictDeleteUrl } from "@/utils/url.js";
import DictForm from "@/views/sys/dict/DictForm.vue";

const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    align: "center",
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "类别",
    dataIndex: "category",
    key: "category",
    align: "center",
  },
  {
    title: "字典名称",
    dataIndex: "label",
    key: "label",
    align: "center",
  },
  {
    title: "字典值",
    dataIndex: "value",
    key: "value",
    align: "center",
  },
  {
    title: "字典描述",
    dataIndex: "description",
    key: "description",
    align: "center"
  },
  {
    title: "排序",
    dataIndex: "sortOrder",
    key: "sortOrder",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "status",
    key: "status",
    align: "center",
    scopedSlots: { customRender: "status" }
  },
  {
    title: "操作",
    dataIndex: "",
    align: "center",
    scopedSlots: { customRender: "action" },
    width: 120,
  },
];
export default {
  components: {
    DictForm,
  },
  data() {
    return {
      columns,
      isLoading: false, // 是否为加载状态
      dataSource: [], // 表格数据

      // 分页参数
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30"],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // 查询列表
    loadData() {
      this.isLoading = true;

      let params = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };

      this.$axios.get(dictPageListUrl, { params }).then((res) => {
        if (res.success) {
          this.dataSource = res.result.records;
          this.pagination.total = res.result.total;
        }
        this.isLoading = false;
      });
    },
    // 分页切换
    handleTableChange(pagination, filters, sorter) {
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field;
        this.isorter.order = "ascend" == sorter.order ? "asc" : "desc";
      }
      this.pagination = pagination;
      this.loadData();
    },
    handleAdd() {
      this.$refs.handleFrom.add();
    },
    // 打开新增
    handleAdd() {
      this.$refs.handleFrom.add();
    },
    // 打开编辑
    handleEdit(record) {
      this.$refs.handleFrom.edit(record);
    },
    // 删除取消
    cancel(e) {
      console.log(e);
      this.$message.warning("取消删除！");
    },
    // 删除
    handleDelete(val) {
      this.isLoading = true;

      let params = {
        id: val,
      };

      this.$axios.delete(dictDeleteUrl, { params }).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.loadData();
        }
        this.isLoading = false;
      });
    }
  },
};
</script>

<style scoped></style>